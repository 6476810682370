(function () {
    /** Calendrier Formidable de base  */

    var calendarDateFormat = 'DD/MM/YYYY';
    var calendarDateLanguage = ($('html').attr('lang')).substring(0, 2);
    var startDate = moment(new Date()).format(calendarDateFormat);

    if ($('[data-jsgroupdate-os]').length > 0) {

        $('[data-jsgroupdate-os]').each(function () {

            var groupes_input_dates_os = $(this).data('jsgroupdate-os');

            if (groupes_input_dates_os) {

                var $InputFrom = $('[data-jsdatestatus="from"]', '[data-jsgroupdate-os="' + groupes_input_dates_os + '"]');
                var $InputTo = $('[data-jsdatestatus="to"]', '[data-jsgroupdate-os="' + groupes_input_dates_os + '"]');

                $('input.date', '[data-jsgroupdate-os="' + groupes_input_dates_os + '"]').each(function () {

                    var container = $(this).parents(".group-date");
                    var inline = true;

                    if (!isTouchDevice()) {
                        $(this).attr('type', 'text');
                        $(this).attr('placeholder', '');
                        $(this).dateRangePicker({
                            inline: inline,
                            stickyMonths: true,
                            singleDate: false,
                            alwaysOpen: false,
                            singleMonth: true,
                            showShortcuts: false,
                            showTopbar: false,
                            monthSelect: true,
                            hoveringTooltip: false,
                            selectForward: false,
                            yearSelect: [2022, 2030],
                            startOfWeek: 'monday',
                            startDate: startDate,
                            customArrowPrevSymbol: '<span class="icon-ico-arrow-left-slider"></span>',
                            customArrowNextSymbol: '<span class="icon-ico-arrow-right-slider"></span>',
                            format: calendarDateFormat,
                            language: calendarDateLanguage,
                            container: container,
                            separator: ' to ',
                            getValue: function () {
                                if ($('[data-jsdatestatus="from"]', '[data-jsgroupdate-os="' + groupes_input_dates_os + '"]').val() && $('[data-jsdatestatus="to"]', '[data-jsgroupdate-os="' + groupes_input_dates_os + '"]').val())
                                    return $('[data-jsdatestatus="from"]', '[data-jsgroupdate-os="' + groupes_input_dates_os + '"]').val() + ' to ' + $('[data-jsdatestatus="to"]', '[data-jsgroupdate-os="' + groupes_input_dates_os + '"]').val();
                                else
                                    return '';
                            },
                            setValue: function (s, s1, s2) {
                                $(this).parent().addClass("has-content");

                                $('[data-jsdatestatus="from"]', '[data-jsgroupdate-os="' + groupes_input_dates_os + '"]').val(s1);
                                $('[data-jsdatestatus="to"]', '[data-jsgroupdate-os="' + groupes_input_dates_os + '"]').val(s2);
                            },
                        }).bind('datepicker-open', function (event, obj) {
                            $('[data-jsdatestatus="from"]', '[data-jsgroupdate-os="' + groupes_input_dates_os + '"]').parent('.wrapper-date').addClass('focus');
                        }).bind('datepicker-first-date-selected', function (event, obj) {
                            $('[data-jsdatestatus="from"]', '[data-jsgroupdate-os="' + groupes_input_dates_os + '"]').parent('.wrapper-date').removeClass('focus');
                            $('[data-jsdatestatus="to"]', '[data-jsgroupdate-os="' + groupes_input_dates_os + '"]').parent('.wrapper-date').addClass('focus');
                        }).bind('datepicker-close', function (event, obj) {
                            $('[data-jsdatestatus="to"]', '[data-jsgroupdate-os="' + groupes_input_dates_os + '"]').parent('.wrapper-date').removeClass('focus');
                            $('[data-jsdatestatus="from"]', '[data-jsgroupdate-os="' + groupes_input_dates_os + '"]').parent('.wrapper-date').removeClass('focus');

                        });
                    } else {
                        $(this).attr('type', 'date');
                    }
                });
            }
        })
    }

    //Catch au submit pour retirer les variables GET du calendrier
    var forms = document.querySelectorAll("#overlay-reservation form");
    if (forms) {
        forms.forEach(function (elem, index, listObj) {
            var isSubmitted = false;

            elem.addEventListener("submit", e => {
                e.preventDefault();
                isSubmitted = true;
                $('.container-form-hebergement div,.container-form-activites div, button', elem).addClass('hide');
                $('.container-form-hebergement,.container-form-activites', elem).css('min-height', '200px');
                $('.loader', elem).removeClass('hide');

                var query = $(":input", elem)
                    .filter(function (index, element) {
                        if (
                            $(element).val() != ''
                            && $(element).val() != 'empty'
                            && $(element).attr('name') != 'y_dp' // élément provenants du datepicker
                            && $(element).attr('name') != 'm_dp' // élément provenants du datepicker
                        ) {
                            return $(element).val();
                        }
                    })
                    .serialize();
                window.location.href = location.toString().replace(location.hash, "") + "?" + query;
            });
        });
    }
})();

