$(".cover-home-emploi input").each(function () {
    $(this).on("input", function () {
        if ($(this).val() != "") {
            $(this).parent().addClass("has-content");
        } else {
            $(this).parent().removeClass("has-content");
        }
    });
});


// Remonte le label si le champ de recherche est déja prérempli par une recherche précédente
const inputSearch = document.querySelector('#recherche-offre-home');
if(inputSearch) {
    setTimeout(function() {
        if(inputSearch.value) {
            inputSearch.parentNode.classList.add("has-content");
        }
    }, 100);
}


var btnCombobox = $(".btn-combobox");
var btnCombobox_text = btnCombobox.text();

btnCombobox.on("click", function (e) {

    e.preventDefault();
    var overlayDispo = $(".moteur-recherche-emploi");
    var comboboxCommunes = $(".js-commune-autocomplete");
    var inputLocation = $(".location-search", comboboxCommunes);
    var btnValidation = $(".btn-tertiary", overlayDispo);


    // On ajoute une classe pour afficher la combobox et changer la hauteur de l'overlay
    overlayDispo.toggleClass("combobox-is-open");

    // Si on click sur un élément qui n'est pas la combobox, on va la fermer
    $(document).mouseup(function (e) {
        if (!$(e.target).hasClass('combobox-communes') && !$(e.target).parents(".combobox-communes").length || $(e.target).hasClass("btn-valider")) {
            overlayDispo.removeClass("combobox-is-open");
        }
    });

    // A l'ouverture de la combobox on met le focus sur le premier input.
    // On est forcé à utiliser un setTimeout sinon ça ne marche pas
    setTimeout(function () {
        if(typeof inputLocation.get(0) !== 'undefined') {
            inputLocation.get(0).focus();
        }
    }, 200);

    // On initialise la librairie trap focus, pour s'assurer que l'utilisateur ne puisse pas sortir de la combobox.

    th_rgaa_focus.init(comboboxCommunes, inputLocation.get(0), btnValidation.get(0));

    // Seulement au click sur le bouton valider le trap focus est desactivé.
    // On va remettre le focus sur le bouton d'ouverture de la combobox
    btnValidation.on("click", function () {
        var new_btn_text = inputLocation.val();
        if(!new_btn_text) {
            new_btn_text = btnCombobox_text;
        }
        btnCombobox.text(new_btn_text);
        overlayDispo.removeClass("combobox-is-open");
        setTimeout(function () {
            btnCombobox.get(0).focus();
        }, 200);
    });

    inputLocation.on("click", function () {
        $(this).attr("aria-expanded", true);
    });


});
