// Fonction qui gère le passage d'un onglet à l'autre pour chaque étape.
// Si on est sur le premier onglet, on désactive le bouton prev, si on est sur le dernier onglet on désactive le bouton next
function showEtape() {
    var etapes = $(".bloc-GR223 .etape");
    var itiPaths = $("#carte-gr223 #DECOUPAGE path");

    etapes.each(function (i) {

        var currentEtape = $(this);
        var btnPrevEtape = $(this).find(".btn-prev-etape");
        var btnNextEtape = $(this).find(".btn-next-etape");

        btnPrevEtape.attr("disabled", "disabled");
        btnPrevEtape.on("click", function () {
            currentEtape.prev().find(".btn-next-etape").removeAttr("disabled");
            if (i > 0) {
                currentEtape.addClass("hide");
                currentEtape.prev().removeClass("hide");
                currentEtape.prev().addClass("show");
                setTimeout(function () {
                    var backPrevBtn = currentEtape.prev().find(".btn-prev-etape");
                    var backNextBtn = currentEtape.prev().find(".btn-next-etape");
                    if (!backPrevBtn.attr("disabled")) {
                        backPrevBtn.focus();
                    } else {
                        backNextBtn.focus();
                    }

                }, 100);

            }
            var mapEtape = "etape-" + (i - 1);

            itiPaths.each(function () {
                if ($(this).attr('id') === mapEtape) {
                    $(this).addClass("is-active");
                } else {
                    $(this).removeClass("is-active");
                    $(this).blur();
                }
            });

        });
        btnNextEtape.on("click", function () {

            currentEtape.next().find(".btn-prev-etape").removeAttr("disabled");
            currentEtape.addClass("hide");
            currentEtape.next().removeClass("hide");
            currentEtape.prev().removeClass("show");
            currentEtape.next().addClass("show");
            setTimeout(function () {
                var backPrevBtn = currentEtape.next().find(".btn-prev-etape");
                var backNextBtn = currentEtape.next().find(".btn-next-etape");
                if (!backNextBtn.attr("disabled")) {
                    backNextBtn.focus();
                } else {
                    backPrevBtn.focus();
                }

            }, 100);


            var noIndex = etapes.length - 1;
            var current = i + 2;

            console.log('current', i + 1);
            var mapEtape = "etape-" + (i + 1);

            itiPaths.each(function () {
                if ($(this).attr('id') === mapEtape) {
                    $(this).addClass("is-active");
                } else {
                    $(this).removeClass("is-active");
                    $(this).blur();
                }

            });

            if (current > noIndex) {
                currentEtape.next().find(".btn-next-etape").attr("disabled", "disabled");
            }

        });

    });
}

showEtape();

// Fonction qui gère le chargement dynamique des overlays contenant la preview sit et les boutons étapes précédente et suivante

function parseBtnGR223ClickOverlay() {

    var btnEtapes = $(".bloc-GR223 .js-card-gr223");

    btnEtapes.each(function (i) {

        $(this).on("keydown", function (e) {
            if (e.keyCode === 32) {
                e.preventDefault();
                $(this).trigger("click");
                $(this).addClass("parsed");
            }
        });
        $(this).on("click", function (e) {

            e.preventDefault();

            var elem = $(this);

            var sit_url = elem.attr('href') + '?overlay';
            var overlay_id = 'overlay-gr223-sit';

            if ($(this).attr("data-etape-btn")) {
                var currentEtape = $(this).attr("data-etape-btn");
                var currentEtapeNumber = parseInt(currentEtape.replace("etape-", ""));
            }


            var calcPrev = i - 1;
            var prevBtn = null;
            // La fonction eq retourne un objet vide lorsque l'index est à 0, ce qui correspond à une valeur true, donc on a le risque d'avoir une boucle infinie.
            // On ajoute donc une condition et on utilise la variable calcPrev pour s'assurer que dans le cas où l'index est mineur ou égale à 0, la valeur de calcPrev est 0 et pas un objet vide

            // On séléctionne le bouton qui doit nous ramener vers l'étape précédente
            if (i - 1 <= 0) {
                calcPrev = 0;
                prevBtn = btnEtapes.eq(0);
            } else {
                prevBtn = btnEtapes.eq(calcPrev);
            }

            // On séléctionne le bouton qui doit nous ramener vers l'étape suivante

            var nextBtn = btnEtapes.eq(i + 1);

            var date = moment($('.dtstart', elem).attr('datetime'), "YYYY-MM-DD").format("Do MMMM");
            var h_from = $($('.hours-js span', elem).get(0)).text();
            var h_to = $($('.hours-js span', elem).get(1)).text();


            function generateAjaxContent(sit_url) {
                $('#' + overlay_id + ' .js-content-overlay-sit').html('');
                $('#' + overlay_id + ' .loader').removeClass('hide');
                $('#' + overlay_id + ' .bottom-part').addClass('hide');
                $('#' + overlay_id + ' .navigation-etapes').addClass('hide');
                $.get(sit_url, function (responseHTML) {
                    $('#' + overlay_id + ' .loader').addClass('hide');
                    $('#' + overlay_id + ' .js-content-overlay-sit').html(responseHTML);
                    $('#' + overlay_id + ' .js-content-overlay-sit .time').html($('time', elem).html());
                    $('#' + overlay_id + ' .bottom-part a').attr('href', elem.attr('href'));
                    $('#' + overlay_id + ' .bottom-part').removeClass('hide');
                    $('#' + overlay_id + ' .navigation-etapes').removeClass('hide');
                    $('#' + overlay_id + ' .date-js').text(date);

                    if (!h_from && !h_to) {
                        $('#' + overlay_id + ' .dt-schedule').addClass('hide');
                    } else if (h_from && !h_to) {
                        $('#' + overlay_id + ' .hours-js-from').removeClass('hide');
                        $('#' + overlay_id + ' .hour-js-from').text(h_from);
                    } else if (h_from && h_to) {
                        console.log('date from and to !!!!');
                        $('#' + overlay_id + ' .hours-js-from-to').removeClass('hide');
                        $('#' + overlay_id + ' .hour-js-from').text(h_from);
                        $('#' + overlay_id + ' .hour-js-to').text(h_to);
                    }
                    var mapsContainer = document.querySelector('#' + overlay_id + ' .maps');
                    if (mapsContainer) {
                        th_maps.initSingleMap(mapsContainer);
                    }
                    setSliderOverlaySit(overlay_id);
                });
                // RGAA : On stock l'element qui a ouvert l'overlay pour y revenir a sa fermeture
                th_overlay.focusElOnClose[overlay_id] = elem;
                th_overlay.open(overlay_id, true, true);

            }

            generateAjaxContent(sit_url);

            var overlayPrevBtn = $("#overlay-gr223-sit .btn-prev");
            var overlayNextBtn = $("#overlay-gr223-sit .btn-next");
            overlayPrevBtn.attr("href", prevBtn.attr("href"));
            overlayNextBtn.attr("href", nextBtn.attr("href"));
            // Par défault, au chargement de l'overlay, si on est sur l'étape 0 le bouton prev doit être sur disabled
            if (currentEtapeNumber <= 0) {
                overlayPrevBtn.attr("disabled", "disabled");
            }
            // Par défault, au chargement de l'overlay, si on est sur la dernière étape le bouton next doit être sur disabled
            if (currentEtapeNumber >= btnEtapes.length - 1) {
                overlayNextBtn.attr("disabled", "disabled");
            }

            // Au click sur le bouton précédent, il faut récupérer la bonne url, et gérer le disabled si on est sur la première étape
            overlayPrevBtn.on("click", function (e) {
                e.preventDefault();

                // Si le bouton next à déjà un attribut disabled on le supprime
                if (overlayNextBtn.attr("disabled")) {
                    overlayNextBtn.removeAttr("disabled");
                }

                currentEtapeNumber = currentEtapeNumber - 1;
                // On va récuperer le contenu sit si on est sur une étape majeure ou égale à 0.
                // Si l'étape est égale à 0, on ajoute aussi un disabled sur le bouton prev, parce que on n'as plus rien à charger
                if (currentEtapeNumber > 0) {
                    btnManager(currentEtapeNumber);
                } else if (currentEtapeNumber === 0) {
                    btnManager(currentEtapeNumber);
                    $(this).attr("disabled", "disabled");
                } else {
                    currentEtapeNumber = 0;
                    $(this).attr("disabled", "disabled");
                }

            });

            // Au click sur le bouton suivant, il faut récupérer la bonne url, et gérer le disabled si on est sur la dernière étape
            overlayNextBtn.on("click", function (e) {
                e.preventDefault();

                if (overlayPrevBtn.attr("disabled")) {
                    overlayPrevBtn.removeAttr("disabled");
                }

                var noZeroIndex = (btnEtapes.length) - 1;

                currentEtapeNumber = currentEtapeNumber + 1;

                if (currentEtapeNumber < noZeroIndex) {
                    $(this).removeAttr("disabled");
                    btnManager(currentEtapeNumber);
                } else if (currentEtapeNumber === noZeroIndex) {
                    btnManager(currentEtapeNumber);
                    $(this).attr("disabled", "disabled");
                } else {
                    currentEtapeNumber = noZeroIndex;
                    $(this).attr("disabled", "disabled");
                }

            });

            function btnManager(currentEtapeNumber) {
                var calcNumb = currentEtapeNumber - 1 + 1;
                var currentEl = btnEtapes.eq(calcNumb);
                var prevBtn = btnEtapes.eq(currentEtapeNumber - 1);
                var nextBtn = btnEtapes.eq(currentEtapeNumber + 1);

                var sit_url = currentEl.attr('href') + '?overlay';

                generateAjaxContent(sit_url);

                var overlayPrevBtn = $("#overlay-gr223-sit .btn-prev");
                var overlayNextBtn = $("#overlay-gr223-sit .btn-next");

                overlayPrevBtn.attr("href", prevBtn.attr("href"));
                overlayNextBtn.attr("href", nextBtn.attr("href"));
            }

        }).addClass('parsed');
    });

}

parseBtnGR223ClickOverlay();
// Fonction qui gère l'action d'afficher ou cacher le bon onglet selon le clique sur le path dans la carte.
// On gère aussi l'état actif sur les paths, lorsqu'on passe d'un étape à l'autre
function showOnglet() {
    var etapes = $(".bloc-GR223 .etape");
    var mapPaths = $("#carte-gr223 #DECOUPAGE path");

    for (var i = 0; i < etapes.length; i++) {

        mapPaths.eq(i).on("click", function (i) {
            var currentEtapeValue = $(this).attr("id");
            // On supprime la classe "is-active" dans les paths qui sont pas celui actif
            mapPaths.each(function () {
                $(this).removeClass("is-active");
            });
            // On ajoute ensuite la classe active sur le path sélectionné
            $(this).addClass("is-active");

            // Si l'étape à un attr data-etape avec la même valeur que l'id du path cliqué on affiche l'étape, autrement on la cache
            etapes.each(function () {
                if ($(this).attr("data-etape") == currentEtapeValue) {
                    $(this).removeClass("hide");
                    $(this).addClass("show");
                } else {
                    $(this).removeClass("show");
                    $(this).addClass("hide");
                }
                if ($(this).attr("data-etape") !== "etape-0") {
                    $(this).find(".btn-prev-etape").removeAttr("disabled");
                }
                if ($(this).attr("data-etape") === "etape-22") {
                    $(this).find(".btn-next-etape").attr("disabled", "disabled");
                }
            });
        });
    }
}

showOnglet();