

// Fonction qui gère la création de la carte
function callbackMapInteractive(macarte) {

    macarte.zoomControl.setPosition('topleft');

    var markers = [];
    var groupsActive = [];
    var groupsFitBounds = [];
    var groupsFitBoundsActive = [];

    $.each(markers_inline,function (i,el) {

        groupsFitBounds[i] = [];

        $.each( el.markers,function () {

            var icon = null;
            var color = 'blue';

            if (el.icon) {
                icon = el.icon;

                th_maps.addMarkerIcon(icon, {
                    className: icon,
                    html: '<div class="marker-pin-actif"><svg class="' + color + '" xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 32.156 42.019">\n' +
                        '  <circle cx="15" cy="20" r="12" fill="black" stroke-width="16" stroke="rgba(0, 0, 0, 0.3)"/>' +
                        '</svg></div>',

                    iconSize: [32, 42],
                    iconAnchor: [16, 21]
                });
            }





            var id = this[0];

            var latlng = {lat: this[3] , lng: this[2] };

            if (latlng.lat != 0 && latlng.lng != 0) {

                var marker = th_maps.createMarker(macarte, latlng, icon, i);

                th_maps.createInfoWindow(this[1], marker, 260);
                markers.push(marker);
                groupsFitBounds[i].push(marker);
            }
        });

        if ( el.checked ) {
            th_maps.showGroupMarker(i, macarte);
            groupsActive.push(i);
        } else {
            th_maps.hideGroupMarker(i, macarte);
            groupsActive = $.grep(groupsActive, function(n) {
                return n != i;
            });
        }

        $('input[name='+i+']').on('change', function () {

            var groupsFitBoundsActive = [];

            var url = new URL(window.location);

            if ($(this).prop('checked')) {
                th_maps.showGroupMarker($(this).val(), macarte);
                url.searchParams.set(i, true);
                groupsActive.push(i);
                window.history.pushState({}, '', url);
            } else {
                url.searchParams.set(i, false);
                th_maps.hideGroupMarker($(this).val(), macarte);
                groupsActive = $.grep(groupsActive, function (n) {
                    return n != i;
                });
            }

            window.history.pushState({}, '', url);



            $.each(groupsActive, function (i, el) {
                $.each(groupsFitBounds[el], function (i, el) {
                    groupsFitBoundsActive.push(el)
                });
            });

            if (groupsFitBoundsActive) {
                var group = new L.featureGroup(groupsFitBoundsActive);
                macarte.fitBounds(group.getBounds());
            }

        });


    });

    $.each(groupsActive,function(i,el) {
        $.each( groupsFitBounds[el], function(i,el) {
            groupsFitBoundsActive.push(el)
        });
    });

    if (groupsFitBoundsActive) {
        var group = new L.featureGroup(groupsFitBoundsActive);
        macarte.fitBounds(group.getBounds());
    }

    if (isTouchDevice()) {
        macarte.dragging.disable();
    }


}
