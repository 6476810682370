/* Initialisation du slider */
(function () {

    function overlayEmploi() {

        var sliders = document.querySelectorAll('.single-emploi .th-slider:not(.no-autoload)');
        for (i = 0; i != sliders.length; i++) {
            var slider = sliders[i];
            initOverlaysEmploiSlider(slider);
        }


        function initOverlaysEmploiSlider(slider) {
            var parent = slider.parentNode;
            while (!parent.className.match(/th-slider-wrapper/)) {
                parent = parent.parentNode;
            }

            var navButtons = parent.querySelectorAll('.nav-buttons button');

            var s = new thSlider(slider, {
                draggable: true,
                scrollListener: true,
                scrollModeMaxWidth: 1024,
                oninit: function (slider) {
                    if (navButtons.length === 2) {
                        thSliderTools.onInitNav(slider, navButtons);
                    }
                },
                onchange: function (slider) {
                    if (navButtons.length > 0) {
                        thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
                    }
                }
            });
        }
    }

    overlayEmploi();

    th_overlay.addCallbackOpen(function (overlay) {
        // Note : la classe "overlay-emploi-info" est partagé entre tous les overlays,
        // parce qu'elle gère les styles communs entre les overlays
        if ($("#" + overlay).hasClass("overlay-emploi-info")) {
            setTimeout(function () {
                overlayEmploi(overlay);

                initform();

                var urlRequest = template_path + '/components/Favoris/ajax/favoris.php';

                th_favoris.init(urlRequest);

            }, 800);

        }
    });

})();


/* Ouverture de la popup pour postuler à l'offre d'emploi */

var singleOffreContainer = $(".single-emploi");

function setModaleCandidature(containerContenuOffre) {

    var btnCandidature = $(".btn-postuler");
    var btnFermerCandidature = $(".btn-fermer-candidature");

    btnCandidature.on("click", function () {
        containerContenuOffre.addClass("show-modale-candidature");
        $("body").addClass("no-scroll");
    });

    btnFermerCandidature.on("click", function () {
        containerContenuOffre.removeClass("show-modale-candidature");
        $("body").removeClass("no-scroll");
    });
}

setModaleCandidature(singleOffreContainer);

th_overlay.addCallbackOpen(function (overlay) {

    // Note : la classe "overlay-emploi-info" est partagé entre tous les overlays,
    // parce qu'elle gère les styles communs entre les overlays
    if ($("#" + overlay).hasClass("overlay-emploi-info")) {

        setTimeout(function () {

            var containerContenuOffre = $("#" + overlay).find(".single-emploi");

            setModaleCandidature(containerContenuOffre);

        }, 800);

    }
});


/* Ajout d'une classe au click sur l'input field pour pouvoir cacher ou bouger le label */

$(".overlay-filtres .recherche input").each(function () {
    $(this).on("input", function () {
        if ($(this).val() != "") {
            $(this).parent().addClass("has-content");
        } else {
            $(this).parent().removeClass("has-content");
        }
    });
});