class thListingPagination {

    options = {
        selectorpagination: null,
        selectorpaginationLight: null,
    };

    listingManager;

    constructor(options, listingManager) {

        this.listingManager = listingManager;

        options = $.extend(true, this.options, options);
        this.options = options;

        this.parse();
    }

    parse() {

        var listingManager = this.listingManager;

        $('a', this.options.selectorpagination).on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            listingManager.ajaxCall(this.getAttribute('href'), true, '', $(this));
        });
    }

    update(html, clickedElement = null) {

        var pagination = $(html).find(this.options.selectorpagination).html();
        if (typeof pagination === 'undefined') {
            pagination = '';
        }

        $(this.options.selectorpagination).html(pagination);
        this.parse();

        if (this.options.selectorpaginationLight) {
            var paginationlight = $(html).find(this.options.selectorpaginationLight).html();
            if (typeof paginationlight === 'undefined') {
                paginationlight = '';
            }
            if (paginationlight) {
                $(this.options.selectorpaginationLight).html(paginationlight);
            }

        }

        // RGAA : Lorsqu'on clique sur le bouton précédent ou suivant (ils ont la classe "nav-links") on met le focus sur le bouton qui à déclenché l'action
        if ( clickedElement !== null) {
            if(clickedElement.hasClass("nav-links")) {
                var classClickedElement = "." + clickedElement.attr("class").split(' ')[0];
                var currentBtnPagination = $(this.options.selectorpagination).find(classClickedElement);
                $(this.options.selectorpagination).find(classClickedElement).focus();
            }
        }

        initCardsSlider();

        // Update <link rel=prev/next href>
        $('link[rel=prev]').attr('href', $('a[rel=prev]').attr('href'));
        $('link[rel=next]').attr('href', $('a[rel=next]').attr('href'));



    }
}