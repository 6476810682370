(function () {
    var btnMenu = $(".btn-menu-open-wrapper");
    var btnMenuCloseContainer = $(".btn-menu-close-wrapper")
    var barreNavigation = $("#barre-navigation");
    var menu = $('#overlay-menu');
    var menuContainer = menu.find('.inner-container');
    var overlayShadow = $("#overlay-shadow");
    var menuButtons = $(".main-navigation .btn-values-wrapper");
    var overlayMenuContainer = $("#overlay-menu .inner-container");
    var btnValues = $(".btn-values-wrapper button");
    var toolPart = menu.find('.tool-part');

    function menuIsOpen() {
        btnMenu.addClass("menu-is-open");
        btnMenuCloseContainer.addClass("show-btn-close")
        barreNavigation.addClass("menu-is-open");
        btnValues.first().addClass("link-highlight");

        // Sur le site au scroll on ajoute une classe sur la barre de navigation pour la faire disparaitre de l'écran
        // Le souci est que dès fois au resize de l'écran, cette classe est declenché, ce qui cause des soucis d'affichage dans l'overlay menu
        //  A l'ouverture du menu on va donc empêcher le scroll

        document.addEventListener('scroll', function (e) {
            e.preventDefault();
            e.stopPropagation();
            return false;
        }, {passive: false});

        // Lorsque le menu est ouvert, au click sur le shadow on force le changement des classes des différents boutons
        overlayShadow.on("click", function () {
            menuIsClosed();
        });
    }

    function menuIsClosed() {
        btnMenu.removeClass("menu-is-open");
        btnMenuCloseContainer.removeClass("show-btn-close")
        barreNavigation.removeClass("menu-is-open");
        menuContainer.children().removeClass("show-menu");
        btnValues.first().removeClass("link-highlight");
        menuButtons.each(function () {
            $(this).find('button').removeClass('link-highlight');
        });
        btnValues.each(function () {
            $(this).attr("aria-expanded", false);
        });
    }

// Au déclenchement de l'ouverture et fermeture du menu
    th_overlay.addCallbackOpen(function (menu) {
        if (menu === "overlay-menu") {
            menuIsOpen();
            // Si à l'ouverture du menu on appuye sur la touche "ESCAPE" (échappe), on doit supprimer toutes les classes ajoutés avec la fonction menuIsOpen
            document.addEventListener('keydown', function (e) {
                if ($("#" + menu) > 0 && e.key === "Escape") {
                    e.preventDefault();
                    e.stopPropagation();
                    // on ferme le menu
                    menuIsClosed();
                }
            });
        }

    });

    th_overlay.addCallbackClose(function (menu) {
        if (menu === "overlay-menu") {
            menuIsClosed();
        }
    });

// Au rechargement de la page avec l'overlay encore ouvert, on a besoin de répéter la gestion des
// classes vu que le th_overlay callBackOpen et close ne marchent pas
    if (menu.hasClass("open")) {
        menuIsOpen();
    } else {
        menuIsClosed();
    }

// Au click sur un des boutons qui déclenchent l'ouverture du megamenu, on change l'attribut aria-expanded à "true"
    btnValues.each(function () {
        $(this).on("click", function () {
            $(this).attr("aria-expanded", true);
        });
    });

// Gestion la version tablette et mobile de mega menu
    var repeatedLinks = menu.find(".btn-values-wrapper");
    var rappelMenu = menu.find(".rappel-menu");
    var bottomPart = menu.find(".bottom-part");
    var menuTopPart = menu.find(".top-part-menu");
    var btnBack = menu.find(".btn-back");
    var btnMenuClose = menu.find(".btn-menu-close");
    repeatedLinks.each(function () {
        if (isTabletPortraitOrSmalller()) {
            // spécial pour la rubrique séjour
            if($(this).hasClass('is-sejour')) {
            } else {
                $(this).on("click", function () {
                    rappelMenu.add(menuTopPart).add(bottomPart).css("display", "none");
                    btnMenuClose.addClass("change-position");
                    toolPart.hide();
                });
            }
        }
    });
    btnBack.add(btnMenuClose).on("click", function () {
        if (isTabletPortraitOrSmalller()) {
            $(this).parents(".main-menu").removeClass("show-menu");
            rappelMenu.add(menuTopPart).add(bottomPart).css("display", "flex");
            btnMenuClose.removeClass("change-position");
            overlayMenuContainer.children().removeClass("show-menu");
            toolPart.show();
        }
    });


    // Gestion des sous-menu pour l'entrée "Votre Séjour"
    const submenuButtons = document.querySelectorAll('.col-left .submenu-button');
    const submenus = document.querySelectorAll('.col-left .submenu-wrapper');
    const btnBackSubmenu = document.querySelectorAll('.col-left .btn-back-submenu');


    // referme tous les sous-menus
    function closeAllSubmenus() {
        submenus.forEach( (el) => {
            el.classList.remove('is-open');
        });

        submenuButtons.forEach( (el) => {
            el.classList.remove('is-open');
        });
    }

    // ouvre un sous-menu
    function openSubmenu(submenuId) {
        const submenu =  document.getElementById(submenuId);
        if (submenu) {
            submenu.classList.add('is-open');
        }
    }


    // Masque le slider optionnel le cas échéant
    function hideMenuSlider(el) {
        const mainMenu = el.closest('.main-menu');

        if(mainMenu) {
            const colRight = mainMenu.querySelector('.col-right');

            if(colRight) {
                colRight.classList.add('is-hidden');
            }
        }
    }

    // Réaffiche le slider optionnel le cas échéant
    function showMenuSlider(el) {
        const mainMenu = el.closest('.main-menu');

        if(mainMenu) {
            const colRight = mainMenu.querySelector('.col-right');

            if(colRight) {
                colRight.classList.remove('is-hidden');
            }
        }
    }

    submenuButtons.forEach( (el) => {
        el.addEventListener('click', () => {
            if (!el.classList.contains('is-open')) {
                // referme tous les sous-menus
                closeAllSubmenus();

                const submenuId = el.dataset.openSubmenu;
                openSubmenu(submenuId);

                el.classList.add('is-open');

                // Masque le slider optionnel le cas échéant
                hideMenuSlider(el);

            } else {
                // referme tous les sous-menus
                closeAllSubmenus();

                el.classList.remove('is-open');

                // Réaffiche le slider optionnel le cas échéant
                showMenuSlider(el);
            }

        });
    });

    btnBackSubmenu.forEach( (el) => {
        el.addEventListener('click', () => {
            // referme tous les sous-menus
            closeAllSubmenus();

            // Réaffiche le slider optionnel le cas échéant
            showMenuSlider(el);
        });
    });


})();