/* Initialisation du slider */
(function () {

    function openOverlaySimple() {

        var sliders = document.querySelectorAll('.overlay-simple .th-slider:not(.no-autoload)');
        for (i = 0; i != sliders.length; i++) {
            var slider = sliders[i];
            initSliderSimple(slider);
        }

        function initSliderSimple(slider) {
            var parent = slider.parentNode;
            while (!parent.className.match(/th-slider-wrapper/)) {
                parent = parent.parentNode;
            }

            var navButtons = parent.querySelectorAll('.nav-buttons button');


            var s = new thSlider(slider, {
                draggable: true,
                scrollListener: true,
                scrollModeMaxWidth: 980,
                oninit: function (slider) {
                    if (navButtons.length === 2) {
                        thSliderTools.onInitNav(slider, navButtons);
                    }

                },
                onchange: function (slider) {
                    if (navButtons.length > 0) {
                        thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
                    }
                }
            });

        }
    }

    openOverlaySimple();

    th_overlay.addCallbackOpen(function (overlay) {
        if (overlay == "overlay-simple") {
            openOverlaySimple(overlay);
        }
    });


})();