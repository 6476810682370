// Returns a function, that, when invoked, will only be triggered at most once
// during a given window of time. Normally, the throttled function will run
// as much as it can, without ever going more than once per `wait` duration;
// but if you'd like to disable the execution on the leading edge, pass
// `{leading: false}`. To disable execution on the trailing edge, ditto.
function throttle(func, wait, options) {
    var context, args, result;
    var timeout = null;
    var previous = 0;
    if (!options) options = {};
    var later = function () {
        previous = options.leading === false ? 0 : Date.now();
        timeout = null;
        result = func.apply(context, args);
        if (!timeout) context = args = null;
    };
    return function () {
        var now = Date.now();
        if (!previous && options.leading === false) previous = now;
        var remaining = wait - (now - previous);
        context = this;
        args = arguments;
        if (remaining <= 0 || remaining > wait) {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            previous = now;
            result = func.apply(context, args);
            if (!timeout) context = args = null;
        } else if (!timeout && options.trailing !== false) {
            timeout = setTimeout(later, remaining);
        }
        return result;
    };
}


var thuriaExploreSearch = {
    el: null,
    ajaxCount: 0,
    action: null,
    lang: null,
    page: 0,
    total: 0,
    totalPagination: 0,
    hasFetchedEdito: false,
    currentSearch: null,
    lockPagination: false,
    currentResults: {},
    sitename: null,
    initRgaa: function () {
        setTimeout(function () {
            th_rgaa_focus.init($(thuriaExploreSearch._selector.overlay));
        }, 200)
    },
    method: "GET",
    _selector: {
        overlay: "#overlay-search",
        form: ".js-recherche-form",
        input: ".js-search-recherche-input",
        results: ".js-search-results",
        resultsCount: "#elastic-recherche-results-count",
        placeholder: "#overlay-search .wrapper-recherche",
    },

    init: function (el) {
        if (!el.hasClass("parsed")) {

            thuriaExploreSearch.el = el;
            thuriaExploreSearch.getDataForm();
            thuriaExploreSearch.searchEvent();
            thuriaExploreSearch.scrollEvent();
            setTimeout(function () {
                $(thuriaExploreSearch._selector.input).focus();
            }, 200);
        }
    },

    getDataForm: function () {
        thuriaExploreSearch.action = $(thuriaExploreSearch._selector.form).attr("action");
        thuriaExploreSearch.lang = $(thuriaExploreSearch._selector.form).data("lang");
        thuriaExploreSearch.sitename = $(thuriaExploreSearch._selector.form).data("sitename");
    },

    scrollEvent: function () {
        $(".js-scroller", thuriaExploreSearch.el).on("scroll", throttle(function () {

            if (thuriaExploreSearch.total > 25 && thuriaExploreSearch.total > thuriaExploreSearch.totalPagination && !thuriaExploreSearch.lockPagination) {
                var height = $(".js-search-results", thuriaExploreSearch.el).height() + 150;
                var scroll = $(".js-scroller", thuriaExploreSearch.el).scrollTop();
                var scrollPercentage = (scroll / height) * 100;
                var percentLimit = 50;

                if (!thuriaExploreSearch.page) {
                    percentLimit = 35;
                }

                if (scrollPercentage > percentLimit) {
                    thuriaExploreSearch.page++;
                    thuriaExploreSearch.totalPagination += 25;

                    thuriaExploreSearch.request();
                }
            }
        }))
    },

    searchEvent: function () {
        // Déjà, on prevent le submit sur le formulaire
        $(thuriaExploreSearch._selector.form).on("submit", function (e) {
            e.preventDefault();
        });


        if ($('.js-predefined-keywords').length > 0) {
            $('.js-predefined-keywords button').on('click', function () {
                var val = $(this).data('mea_word');
                $(thuriaExploreSearch._selector.input).val(val).trigger('input');

            })
        }

        // Puis on attache l'event sur l'input de l'input (Xd)
        $(thuriaExploreSearch._selector.input).on("input", function () {

            if (
                this.value
                && thuriaExploreSearch.currentSearch !== this.value
            ) {
                thuriaExploreSearch.currentSearch = this.value;

                // Dès qu'on commence à écrire dans le champs recherche on ajoute une classe afin d'avoir l'overlay en version full
                $(thuriaExploreSearch.el).addClass("menu-search-open");

                // en on supprime la classe "partially-open" pour ne pas limiter la width du menu
                $(thuriaExploreSearch.el).removeClass("partially-open");

                // Reset du placeholder
                $(thuriaExploreSearch._selector.placeholder).removeClass("display-no-results");
                $(thuriaExploreSearch._selector.placeholder).removeClass("display-placeholder");
                $(thuriaExploreSearch._selector.overlay).removeClass("no-results");

                // Reset des résultats
                $(thuriaExploreSearch._selector.results).html("");

                // Reset du total count
                thuriaExploreSearch.total = 0;

                // Reset de l'edito, pour en fetch de nouveau
                thuriaExploreSearch.hasFetchedEdito = false;

                // Reset de la page
                thuriaExploreSearch.page = 0;

                // Reset de la page
                thuriaExploreSearch.totalPagination = 0;

                // Envoi de la requête
                thuriaExploreSearch.request();

                thuriaExploreSearch.initRgaa();
            } else if (!this.value) {
                $(thuriaExploreSearch._selector.placeholder).removeClass("display-no-results");
                $(thuriaExploreSearch._selector.placeholder).addClass("display-placeholder");
                $(thuriaExploreSearch._selector.resultsCount).html('');
                $(thuriaExploreSearch._selector.results).html("");
                thuriaExploreSearch.initRgaa();
            }
        });
    },

    updateResults: function (hits) {

        var htmlContainer = document.createElement("div");

        if (hits.length) {

            $.each(hits, function (index, value) {
                var card_html = $.parseHTML(value._source.cards_html);

                $.each(card_html, function (i, el) {
                    $(el).find(".js-card-sit-overlay").removeAttr("role");
                    if ($(el).hasClass("card-container") || $(el).hasClass("card") || $(el).hasClass("card-manche-emploi")) {
                        htmlContainer.appendChild(el);
                    }
                    $(this).wrap("<li class='inner-card-recherche'></li>");

                })
            });
        }

        $(thuriaExploreSearch._selector.results).append($(htmlContainer).html());
        th_favoris.parseItems();
        th_favoris.addEvents();
    },

    updateResultsCount: function () {
        if ($(thuriaExploreSearch._selector.resultsCount).hasClass("hidden")) {
            $(thuriaExploreSearch._selector.resultsCount).removeClass("hidden");
        }

        if (thuriaExploreSearch.total > 0) {
            $(thuriaExploreSearch._selector.placeholder).removeClass("display-no-results");
            $(thuriaExploreSearch._selector.overlay).removeClass("no-results");
        } else {
            $(thuriaExploreSearch._selector.placeholder).addClass("display-no-results");
            $(thuriaExploreSearch._selector.overlay).addClass("no-results");
        }

        var text = "<strong>" + thuriaExploreSearch.total + " " + $(thuriaExploreSearch._selector.resultsCount).data("text") + "</strong> ";

        $(thuriaExploreSearch._selector.resultsCount).html(text);
    },

    request: function () {
        thuriaExploreSearch.ajaxCount++;
        var ajaxCount = thuriaExploreSearch.ajaxCount;
        var query = thuriaExploreSearch.composeQuery(thuriaExploreSearch.currentSearch);
        thuriaExploreSearch.lockPagination = true;

        if (thuriaExploreSearch.action) {
            if (!thuriaExploreSearch.hasFetchedEdito) {
                // Création de la query pour la _msearch query
                var mQuery = [];
                mQuery.push(query);
                var mQueryParsed = mQuery.map(JSON.stringify).join('\n');

                $.ajax({
                    type: "POST",
                    url: thuriaExploreSearch.action + '_search',
                    dataType: "json",
                    contentType: "application/json; charset=utf-8",
                    data: mQueryParsed += "\n",
                    success: function (res) {

                        if (ajaxCount === thuriaExploreSearch.ajaxCount && res.hits && res.hits.hits.length) {

                            thuriaExploreSearch.total += res.hits.total.value;
                            thuriaExploreSearch.updateResults(res.hits.hits);

                            thuriaExploreSearch.hasFetchedEdito = true;
                            thuriaExploreSearch.updateResultsCount();
                            thuriaExploreSearch.lockPagination = false;
                        } else {
                            thuriaExploreSearch.updateResultsCount();
                        }
                    }
                });
            } else {
                $.ajax({
                    type: "POST",
                    url: thuriaExploreSearch.action + '_search',
                    dataType: "json",
                    contentType: "application/json; charset=utf-8",
                    data: JSON.stringify(query),
                    success: function (res) {
                        if (ajaxCount === thuriaExploreSearch.ajaxCount) {
                            thuriaExploreSearch.updateResults(res.hits.hits);

                            thuriaExploreSearch.lockPagination = false;
                        }
                    }
                });
            }
        } else {
            console.warn("L'URL ElasticSearch n'est pas renseignée. Veuillez contacter le webmaster.");
        }
    },

    composeQuery: function (value) {

        var indice_base_name = thuriaExploreSearch.sitename + '_' + thuriaExploreSearch.lang + '_';
        var indices_boost = {};
        indices_boost[indice_base_name + 'page'] = 100;

        var baseQuery = {
            "query": {},
            "size": 20,
            'indices_boost': [],
        };

        baseQuery.indices_boost.push(indices_boost)

        baseQuery.query = {
            "function_score": {
                "query": {
                    "bool": {
                        "must_not": {
                            "term": {"valid": false}
                        },
                        "should": []
                    }
                },
            },

        };
        var words = value.split(" ");

        // 1 - a/ Perfect Match - post_title
        baseQuery.query.function_score.query.bool.should.push({
            "bool": {
                "boost": 12,
                "must": [
                    {
                        "term": {
                            "post_title": value
                        }
                    }
                ],
                '_name': 'Perfect Match - post_title'
            }
        });

        // 1 - b/ Match_phrase - post_title
        baseQuery.query.function_score.query.bool.should.push({
            "bool": {
                "boost": 11,
                "must": [
                    {
                        "match_phrase": {
                            "post_title.search": {
                                "query": value,
                                '_name': 'Match_phrase - post_title'
                            }
                        }
                    }
                ]
            }
        });

        // les fuzzy doivent avoir moins de poids que les recherches exactes
        // 1 - b/ Match - post_title
        baseQuery.query.function_score.query.bool.should.push({
            "bool": {
                "boost": 10,
                "must": [
                    {
                        "match": {
                            "post_title.search": {
                                "query": value,
                                '_name': 'Match - post_title'
                            }
                        }
                    }
                ]
            }
        });

        // 3 - Fuzzy query - post_title
        baseQuery.query.function_score.query.bool.should.push({
            "bool": {
                "boost": 9,
                "must": [
                    {
                        "match": {
                            "post_title.search": {
                                "query": value,
                                "fuzziness": "AUTO",
                                "max_expansions": 5, // Maximum number of variations created. Defaults to 50.
                                "prefix_length": 2, // Number of beginning characters left unchanged when creating expansions. Defaults to 0.
                                "minimum_should_match": "80%",
                                '_name': 'Fuzzy query - post_title'
                            }
                        }
                    }
                ]
            }
        });

        // 4 - Recherche dans le surtitre
        baseQuery.query.function_score.query.bool.should.push({
            "bool": {
                "boost": 7,
                "should": [
                    {
                        "term": {
                            "surtitre": value
                        }
                    },
                    {
                        "term": {
                            "sstitre": value
                        }
                    },
                    {
                        "term": {
                            "type": value
                        }
                    },
                    {
                        "term": {
                            "commune": value
                        }
                    }
                ],
                '_name': 'Perfect Match - surtitre | type | commune'
            }
        });

        baseQuery.query.function_score.query.bool.should.push({
            "bool": {
                "boost": 6,
                "should": [
                    {
                        "match": {
                            "surtitre": {
                                "query": value,
                                "fuzziness": "AUTO",
                                "max_expansions": 5, // Maximum number of variations created. Defaults to 50.
                                "prefix_length": 2, // Number of beginning characters left unchanged when creating expansions. Defaults to 0.
                                "minimum_should_match": "80%",
                                '_name': 'Fuzzy query - surtitre'
                            }
                        }
                    },
                    {
                        "match": {
                            "sstitre": {
                                "query": value,
                                "fuzziness": "AUTO",
                                "max_expansions": 5, // Maximum number of variations created. Defaults to 50.
                                "prefix_length": 2, // Number of beginning characters left unchanged when creating expansions. Defaults to 0.
                                "minimum_should_match": "80%",
                                '_name': 'Fuzzy query - sstitre'
                            }
                        }
                    },
                    {
                        "match": {
                            "type": {
                                "query": value,
                                "fuzziness": "AUTO",
                                "max_expansions": 5, // Maximum number of variations created. Defaults to 50.
                                "prefix_length": 2, // Number of beginning characters left unchanged when creating expansions. Defaults to 0.
                                "minimum_should_match": "80%",
                                '_name': 'Fuzzy query - type'
                            }
                        }
                    },
                    {
                        "match": {
                            "commune": {
                                "query": value,
                                "fuzziness": "AUTO",
                                "max_expansions": 5, // Maximum number of variations created. Defaults to 50.
                                "prefix_length": 2, // Number of beginning characters left unchanged when creating expansions. Defaults to 0.
                                "minimum_should_match": "80%",
                                '_name': 'Fuzzy query - commune'
                            }
                        }
                    }
                ]
            }
        });


        // 6 - Recherche dans les tags
        baseQuery.query.function_score.query.bool.should.push({
            "bool": {
                "boost": 6,
                "must": [
                    {
                        "term": {
                            "tags": value
                        }
                    },
                ],
                '_name': 'Perfect Match - tags'
            }
        });

        baseQuery.query.function_score.query.bool.should.push({
            "bool": {
                "boost": 5,
                "must": [
                    {
                        "match": {
                            "tags": {
                                "query": value,
                                "fuzziness": "AUTO",
                                "max_expansions": 5, // Maximum number of variations created. Defaults to 50.
                                "prefix_length": 2, // Number of beginning characters left unchanged when creating expansions. Defaults to 0.
                                "minimum_should_match": "80%",
                                '_name': 'Fuzzy Match - tags'
                            }
                        }
                    }
                ]
            }
        });

        // avec un seul mot on ne recherche volontairement pas dans le contenu pour éviter trop de résultats parasites
        if (words.length > 2) {
            var wordsCopy = words.slice();
            var wordsWithoutArticles = thuriaExploreSearch.removeArticles(wordsCopy);

            // 1 - a/ Match Span Query dans l'ordre - post_title
            baseQuery.query.function_score.query.bool.should.push(thuriaExploreSearch.formatSpanQuery("post_title", wordsWithoutArticles, 12, 0, true));

            // 1 - a/ Match Span Query pas dans l'ordre - post_title
            baseQuery.query.function_score.query.bool.should.push(thuriaExploreSearch.formatSpanQuery("post_title", wordsWithoutArticles, 11, 2, false));

            // 2 - Recherche dans les tags
            baseQuery.query.function_score.query.bool.should.push({
                "bool": {
                    "boost": 12,
                    "must": [
                        {
                            "term": {
                                "tags": value
                            }
                        }
                    ]
                }
            });

            baseQuery.query.function_score.query.bool.should.push({
                "bool": {
                    "boost": 12,
                    "must": [
                        {
                            "fuzzy": {
                                "tags": {
                                    "value": value,
                                    "fuzziness": "AUTO",
                                    "max_expansions": 5, // Maximum number of variations created. Defaults to 50.
                                    "prefix_length": 2, // Number of beginning characters left unchanged when creating expansions. Defaults to 0.
                                }
                            }
                        }
                    ]
                }
            });

            // 3 - a/ Match Span Query dans l'ordre  - Surtitre
            baseQuery.query.function_score.query.bool.should.push(thuriaExploreSearch.formatSpanQuery("surtitre", wordsWithoutArticles, 8, 0, true));

            // 3 - b/ Match Span Query pas dans l'ordre - Surtitre
            baseQuery.query.function_score.query.bool.should.push(thuriaExploreSearch.formatSpanQuery("surtitre", wordsWithoutArticles, 7, 2, false));

            // 4 - a/ Match Span Query dans l'ordre - Sous Titre
            baseQuery.query.function_score.query.bool.should.push(thuriaExploreSearch.formatSpanQuery("sstitre", wordsWithoutArticles, 8, 0, true));

            // 4 - b/ Match Span Query pas dans l'ordre - Sous Titre
            baseQuery.query.function_score.query.bool.should.push(thuriaExploreSearch.formatSpanQuery("sstitre", wordsWithoutArticles, 7, 2, false));

            // 5 - a/ Match Span Query dans l'ordre  - Accroche
            baseQuery.query.function_score.query.bool.should.push(thuriaExploreSearch.formatSpanQuery("accroche", wordsWithoutArticles, 8, 0, true));

            // 5 - b/ Match Span Query pas dans l'ordre - Accroche
            baseQuery.query.function_score.query.bool.should.push(thuriaExploreSearch.formatSpanQuery("accroche", wordsWithoutArticles, 7, 2, false));

            // 6 - a/ Match Span Query dans l'ordre - Intro
            baseQuery.query.function_score.query.bool.should.push(thuriaExploreSearch.formatSpanQuery("intro", wordsWithoutArticles, 8, 0, true));

            // 6 - b/ Match Span Query pas dans l'ordre - Intro
            baseQuery.query.function_score.query.bool.should.push(thuriaExploreSearch.formatSpanQuery("intro", wordsWithoutArticles, 7, 2, false));
        }

        // Pagination
        if (thuriaExploreSearch.page > 0) {
            baseQuery.from = baseQuery.size * thuriaExploreSearch.page;
        }

        return baseQuery;
    },

    removeArticles: function (words) {
        var articles = thuriaExploreSearch.getArticlesLang(thuriaExploreSearch.lang);

        // Clear les articles et les espaces
        $.each(words, function (i, value) {
            if (value && articles.indexOf(value) !== -1) {
                words.splice(i, 1);
            }

            if (value === "") {
                words.splice(i, 1);
            }
        });

        return words;
    },

    getArticlesLang: function (lang) {
        switch (lang) {
            case "fr":
                return [
                    "un", "une", "des", "le", "la", "les", "au", "du", "à", "la", "aux", "des", "de"
                ];
            case "en":
                return [
                    "a", "the", "an"
                ];
        }

        return false;
    },

    formatSpanQuery: function (field, words, boost, slop, order) {
        // Création du span de base qui va contenir les arguments
        var baseSpan = {
            "bool": {
                "boost": boost,
                "must": [
                    {
                        "span_near": {
                            "clauses": [],
                            "slop": slop,
                            "in_order": order,
                        }
                    }
                ]
            },
        };

        // Parsing des mots pour les rajouter dans la clause du spanQuery
        $.each(words, function (i, value) {
            var tempObject = {
                "span_multi": {
                    "match": {
                        "fuzzy": {}
                    }
                }
            };

            tempObject.span_multi.match.fuzzy[field] = {
                'value': value,
                'fuzziness': "AUTO",
                "max_expansions": 5, // Maximum number of variations created. Defaults to 50.
                "prefix_length": 2, // Number of beginning characters left unchanged when creating expansions. Defaults to 0.
                '_name': 'Fuzzy query - ' + field
            };

            baseSpan.bool.must[0].span_near.clauses.push(tempObject);
        });

        return baseSpan;
    }
};

th_overlay.addCallbackOpen(function (overlay_id) {

    if (overlay_id === "overlay-search") {

        var el = $("#" + overlay_id);

        if (el.length) {

            var firstEl = $("#elastic-recherche-input", el)[0];
            setTimeout(function () {
                firstEl.focus();
            }, 1000);

            var lastEl = $(".btn-close", el);


            setTimeout(function () {
                thuriaExploreSearch.init(el);

                // Scroll en haut de l'overlay de recherche
                $(".js-search-results", el).animate({
                    scrollTop: 0
                }, 250);
            });

            setTimeout(function () {
                th_rgaa_focus.init(el);
            }, 800);

        }

    }
});