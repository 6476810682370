(function () {

    /*
 *   This content is licensed according to the W3C Software License at
 *   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
 *
 *   File:   tabs-automatic.js
 *
 *   Desc:   Tablist widget that implements ARIA Authoring Practices
 */

    // Select sur les single SIT dans les blocs Tarifs, horaires, salles
    // Agit comme un tab / tabpanel

    'use strict';

    class TabsAutomaticSelect {
        constructor(groupNode) {
            this.selectNode = groupNode;

            this.options = Array.from(this.selectNode.querySelectorAll('option'));
            this.tabpanels = [];

            this.options.forEach(option => {
                const panelId = option.dataset.panelId;
                const tabpanel = document.getElementById(panelId);
                this.tabpanels.push(tabpanel);
            });
            this.selectNode.addEventListener('change', this.onChange.bind(this));
        }

        onChange(event) {
            const currentOption = this.selectNode.options[this.selectNode.selectedIndex];

            for (let i = 0; i < this.options.length; i += 1) {
                const option = this.options[i];
                if (currentOption === option) {
                    this.tabpanels[i].removeAttribute('hidden');
                } else {
                    this.tabpanels[i].setAttribute('hidden', true);
                }
            }
        }
    }

    // Initialize tablist
    window.addEventListener('load', function () {
        const selects = document.querySelectorAll('.js-select-tabpanel');
        selects.forEach(select => {
            new TabsAutomaticSelect(select);
        });
    });

})();
