var fontSwitch = $("#typography-switch");
var rootElement = document.querySelector(':root');

// Au click sur le bouton "rendre la typographie accessible" il faut remplacer le font "Hackney" par "Proxima Nova"

var checkbox = document.getElementById('typography-switch');

// Fonction pour sauvegarder dans le local storage l'état checked/unchecked de la checkbox
function setCheckedStatus() {
    localStorage.setItem('typography-switch', checkbox.checked);
}

// Fonction pour pouvoir récupérer l'état de la checkbox
function getCheckedStatus() {
    var checked = JSON.parse(localStorage.getItem('typography-switch'));
    if (checked) {
        rootElement.style.setProperty('--spe-font', 'proxima-nova-extra-condensed');
        fontSwitch.prop("checked", true);
    } else {
        rootElement.style.setProperty('--spe-font', 'Hackney');
        fontSwitch.prop("checked", false);
    }
}

fontSwitch.on("click", function () {
    setCheckedStatus();
    getCheckedStatus();
});

// Fonction pour changer la valeur du font Hackney au load de la page, sinon on risque d'avoir toujours le Hackney au chargement de la page
$(document).ready(function () {
    getCheckedStatus();
});