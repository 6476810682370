$(document).ready(function () {

    var selector_facette_os = '.js-facette-opensystem';
    var selector_facette_os_hidden = '.js-facette-opensystem-hidden';
    var selector_facette_os_mobile = '.js-facette-opensystem-mobile';

    if(
        $(selector_facette_os_hidden).length > 0
        && $(selector_facette_os).length > 0
        && $(selector_facette_os_mobile).length > 0
    ) {

        var $InputFrom = $('[data-jsdatestatus="from"]', selector_facette_os_hidden);
        var $InputTo = $('[data-jsdatestatus="to"]', selector_facette_os_hidden);

        var $InputFromMea = $('[data-jsdatestatus="from"]', selector_facette_os);
        var $InputToMea = $('[data-jsdatestatus="to"]', selector_facette_os);

        var $DisplayFromMobile = $('[data-jsdatestatus="from"]', selector_facette_os_mobile);
        var $DisplayToMobile = $('[data-jsdatestatus="to"]', selector_facette_os_mobile);

        var $rangeCalendarAlwaysOpen =  $('#calendar-container',selector_facette_os);

        // si on arrive sur un listing ayant déjà des paramètres de date de renseignées
        var on_load_from = $InputFrom.val();
        var on_load_to = $InputTo.val();

        /*********************
         * Gestion du calendrier toujours ouvert par défaut
         * ************************/
        var calendarDateFormat = 'DD/MM/YYYY';
        var siteLanguage = $('html').attr('lang');
        var calendarDateLanguage = siteLanguage.substr(0, siteLanguage.indexOf('-'));
        var startDate = moment(new Date()).format(calendarDateFormat);

        /** Calendrier Formidable de base  */
        if (!isTouchDevice()) {
            $rangeCalendarAlwaysOpen.attr('type', 'text');
            $rangeCalendarAlwaysOpen.attr('placeholder', '');
            $rangeCalendarAlwaysOpen.dateRangePicker({
                inline: true,
                container: '#calendar-container',
                hoveringTooltip: false,
                alwaysOpen: true,
                singleDate: false,
                singleMonth: false,
                showShortcuts: false,
                stickyMonths: true,
                selectForward: false,
                showTopbar: false,
                monthSelect: true,
                yearSelect: [2020, 2030],
                startOfWeek: 'monday',
                startDate: startDate,
                customArrowPrevSymbol: '<span class="icon-ico-arrow-left-slider"></span>',
                customArrowNextSymbol: '<span class="icon-ico-arrow-right-slider"></span>',
                format: calendarDateFormat,
                language: calendarDateLanguage,
                separator: ' to ',
                getValue: function () {
                    if ($('[data-jsdatestatus="from"]', selector_facette_os).val() && $('[data-jsdatestatus="to"]', selector_facette_os).val())
                        return $('[data-jsdatestatus="from"]', selector_facette_os).val() + ' to ' + $('[data-jsdatestatus="to"]', selector_facette_os).val();
                    else
                        return '';
                },
                setValue: function (s, s1, s2, inst) {
                    $(this).parent().addClass("has-content");

                    var from = s1;
                    var to = s2;

                    if (from && from.match(/\//)) {
                        from = from.split('/').reverse().join('-');
                    }
                    if (to && to.match(/\//)) {
                        to = to.split('/').reverse().join('-');
                    }


                    if($('[data-jsdatelabel]').length > 0) {
                        var label_btn = $('[data-jsdatelabel]').data('textdu');
                        label_btn += ' ' + s1;
                        label_btn += ' ' + $('[data-jsdatelabel]').data('textau');
                        label_btn += ' ' + s2;

                        $('[data-jsdatelabel]').text(label_btn);
                        $('[data-jsdatelabel]').closest('.btn-date').addClass('btn-date--with-date');
                    }

                    $('[data-jsdatestatus="from"]', selector_facette_os).val(from);
                    $('[data-jsdatestatus="to"]', selector_facette_os).val(to);
                    $('[data-jsdatestatus="from"]', selector_facette_os_hidden).val(from);
                    $('[data-jsdatestatus="to"]', selector_facette_os_hidden).val(to);
                },
            });
        }

        $InputFromMea.on('change',function () {
            if (!isTouchDevice()) {
                calendarListingDataOpensystem.set($(this).val(),$InputToMea.val());
            } else {
                $InputFrom.val($(this).val());
                const date = new Date($(this).val());
                const dateFormat = new Intl.DateTimeFormat("fr-FR", {day: 'numeric', month: 'numeric', year: '2-digit'}).format(date)
                $DisplayFromMobile.closest('.btn-date').addClass('btn-date--with-date');
                $DisplayFromMobile.text(dateFormat);
            }
        })

        $InputToMea.on('change',function () {
            if (!isTouchDevice()) {
                calendarListingDataOpensystem.set($InputFromMea.val(), $(this).val());
            } else {
                $InputTo.val($(this).val());
                const date = new Date($(this).val());
                const dateFormat = new Intl.DateTimeFormat("fr-FR", {day: 'numeric', month: 'numeric', year: '2-digit'}).format(date)
                $DisplayToMobile.closest('.btn-date').addClass('btn-date--with-date');
                $DisplayToMobile.text(dateFormat);
            }
        })

        var calendarListingDataOpensystem = {

            from: null,
            to: null,
            submit: false,

            timeout: null,

            set: function (from, to, submit) {

                if(typeof submit != 'undefined') {
                    calendarListingDataOpensystem.submit = submit;
                }

                if (from.match(/\//)) {
                    from = from.split('/').reverse().join('-');
                }
                if (to.match(/\//)) {
                    to = to.split('/').reverse().join('-');
                }

                console.log(from, to);

                if (from !== calendarListingDataOpensystem.from || to !== calendarListingDataOpensystem.to) {
                    calendarListingDataOpensystem.from = from;
                    calendarListingDataOpensystem.to = to;

                    calendarListingDataOpensystem.onchange();
                    return true;
                }
                return false;
            },

            onchange: function () {

                clearTimeout(calendarListingDataOpensystem.timeout);
                calendarListingDataOpensystem.timeout = setTimeout(function () {
                    calendarListingDataOpensystem.onchangeDelais();
                }, 100);

            },

            onchangeDelais: function () {

                if(calendarListingDataOpensystem.from && calendarListingDataOpensystem.to) {
                    $InputFrom.val(calendarListingDataOpensystem.from);
                    $InputTo.val(calendarListingDataOpensystem.to);
                }

                // force la soumission des filtres
                if(calendarListingDataOpensystem.submit) {
                    $InputFrom.trigger('force-change');
                }

                var fromSlash = calendarListingDataOpensystem.from.split('-').reverse().join('/');
                var toSlash = calendarListingDataOpensystem.to.split('-').reverse().join('/');


                if ($rangeCalendarAlwaysOpen.length > 0 && !isTouchDevice()) {
                    $rangeCalendarAlwaysOpen.data('dateRangePicker').setDateRange(fromSlash, toSlash);
                }
            }
        };

        // si on arrive sur un listing ayant déjà des paramètres de date de renseignées
        if(on_load_from && on_load_to){
            calendarListingDataOpensystem.set(on_load_from, on_load_to, false);
        }

        /*********************
         * Actualisation des inputs hidden pour nb personnes, rayon. Pour la commune on le fait lors de la soumission du form
         * ************************/

        $('[name="opensystem_nbpers"]',selector_facette_os).on('change',function () {
            $('[name="opensystem_nbpers"]',selector_facette_os_hidden).val($(this).val());
        })

        $('[name="place_rayon"]',selector_facette_os).on('change',function () {
            $('[name="place_rayon"]',selector_facette_os_hidden).val($(this).val());
        })




        /*********************
         * Soumission du formulaire de filtrage dans l'overlay filtre principal
         * ************************/
        
        $('[type="submit"]',selector_facette_os).on("click",function () {

            let place_search = $('[name="place_search"]',selector_facette_os).val();

            $('[name="place_search"]',selector_facette_os_hidden).val(place_search);

            // si on utilise la recherche par ville on reset les champs geoloc pour éviter un conflit de recherche par localisation sur 2 endroits différents en même temps
            if(place_search) {
                $('[name=geoloc_lat]').val('');
                $('[name=geoloc_lng]').val('');
            }

            listingSitDefault.formEventHandler.form.trigger('submit');
            th_overlay.close('overlay-disponibilites');
        })


    }


});