/* Initialisation du slider */
(function () {

    var sliders = document.querySelectorAll('.bloc-GR223 .th-slider.no-autoload');
    for (i = 0; i != sliders.length; i++) {
        var slider = sliders[i];
        initSlider(slider);
    }

    function initSlider(slider) {

        var parent = slider.closest(".etape");

        var navButtons = parent.querySelectorAll('.nav-buttons button');

        var s = new thSlider(slider, {
            draggable: true,
            scrollListener: true,
            scrollModeMaxWidth: 1024,
            oninit: function (slider) {
                if (navButtons.length === 2) {
                    thSliderTools.onInitNav(slider, navButtons);
                }

            },
            onchange: function (slider) {
                if (navButtons.length > 0) {
                    thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
                }

            }
        });


    }

})();