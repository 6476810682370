/*********************
 * Gestion de la combobox qui apparait au click sur le bouton "communes"
 * ************************/
var overlayDispo = $("#overlay-disponibilites");
var overlayShadow = $("#overlay-shadow");
var btnCommune = $(".btn-commune", overlayDispo);
var comboboxCommunes = $(".js-commune-autocomplete", overlayDispo);
var inputLocation = $(".location-search", comboboxCommunes);
var btnValidation = $(".btn-tertiary", comboboxCommunes);
var btnValidation_text = btnCommune.text();


btnCommune.on("click", function (e) {

    // On ajoute une classe pour afficher la combobox et changer la hauteur de l'overlay
    overlayDispo.toggleClass("combobox-is-open");
    comboboxCommunes.toggleClass("show-combobox");
    if (overlayDispo.hasClass("combobox-is-open")) {
        if (e.key === "Escape") {
            overlayDispo.removeClass("combobox-is-open");
            overlayDispo.addClass("open");
        }
    }

    // Si on click sur un élément qui n'est pas la combobox, on va la fermer
    $(document).mouseup(function (e) {
        if (!comboboxCommunes.is(e.target) && !comboboxCommunes.has(e.target).length) {
            overlayDispo.removeClass("combobox-is-open");
            comboboxCommunes.removeClass("show-combobox");
        }
    });

    // A l'ouverture de la combobox on met le focus sur le premier input.
    // On est forcé à utiliser un setTimeout sinon ça ne marche pas
    setTimeout(function () {
        inputLocation.get(0).focus();
    }, 200);

    // On initialise la librairie trap focus, pour s'assurer que l'utilisateur ne puisse pas sortir de la combobox.

    th_rgaa_focus.init(comboboxCommunes, inputLocation.get(0), btnValidation.get(0));

    // Seulement au click sur le bouton valider le trap focus est desactivé.
    // On va remettre le focus sur le bouton d'ouverture de la combobox
    btnValidation.on("click", function () {

        var new_btn_text = inputLocation.val();
        if(!new_btn_text) {
            new_btn_text = btnValidation_text;
        }
        btnCommune.text(new_btn_text);

        overlayDispo.removeClass("combobox-is-open");
        comboboxCommunes.removeClass("show-combobox");
        setTimeout(function () {
            btnCommune.get(0).focus();
        }, 200);
    });

    inputLocation.on("click", function () {
        $(this).attr("aria-expanded", true);
    });
});

// RGAA : Gestion de l'overlay disponibilités (à l'appui sur la touche échappe si la combobox
// est ouverte on ne ferme pas l'overlay générale, mais juste la combobox
th_overlay.addCallbackOpen(function (overlayId) {
    if (overlayId === "overlay-disponibilites") {
        th_overlay.doNotCloseOverlay = true;

        $(document).on('keyup', function (event) {
            if (event.key == "Escape") {
                if (!overlayDispo.hasClass("combobox-is-open")) {
                    overlayDispo.removeClass("open");
                    overlayShadow.removeClass("open");
                } else {
                    comboboxCommunes.removeClass("show-combobox");
                    setTimeout(function () {
                        overlayDispo.removeClass("combobox-is-open");
                    }, 250);
                }

            }
        });

    }
});