if (document.body.clientWidth < 1150) {
    (function () {

        var sliders = document.querySelectorAll('.bloc-services .th-slider:not(.no-autoload)');
        for (i = 0; i != sliders.length; i++) {
            var slider = sliders[i];
            initSlider(slider);
        }

        function initSlider(slider) {
            var parent = slider.parentNode;
            while (!parent.className.match(/th-slider-wrapper/)) {
                parent = parent.parentNode;
            }

            var navButtons = parent.querySelectorAll('.nav-buttons button');
            var dotsWrapper = parent.querySelectorAll('.nav-dots');


            var reverse = 0;
            if (slider.className.match(/reverse/) && !isTabletPortraitOrSmalller()) {
                reverse = 1;
            }

            var s = new thSlider(slider, {
                draggable: true,
                rtl: reverse,
                scrollListener: true,
                oninit: function (slider) {

                    if (navButtons.length === 2) {
                        thSliderTools.onInitNav(slider, navButtons);
                    }

                    if (dotsWrapper) {
                        thSliderTools.onInitDots(slider, dotsWrapper[0]);
                    }

                },
                onchange: function (slider) {

                    if (navButtons.length > 0) {
                        thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
                    }
                    if (dotsWrapper) {
                        thSliderTools.onChangeDots(slider, dotsWrapper[0]);
                    }
                }
            });

        }

    })();
}

