if($('.overlay-map-single-point').length > 0 ) {

    th_overlay.addCallbackOpen(function (overlayId) {

        var exploded_id = overlayId.split('-');

        if(exploded_id.length == 5) {
            var start_overlay_id = exploded_id[0] + '-' + exploded_id[1] + '-' + exploded_id[2] + '-' + exploded_id[3];
            var num_overlay = exploded_id[4];
            if (exploded_id[0])
                if (start_overlay_id == 'overlay-map-single-point') {
                    setTimeout(function () {
                        th_maps.initSingleMap(document.querySelector('#map-single'+'-'+num_overlay));
                    }, 250);
                }
        }

    });
}