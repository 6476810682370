// Initialisation du slider dans les overlays sit
function setSliderOverlaySit() {

    var sliders = document.querySelectorAll('#overlay-sit .th-slider:not(.no-autoload)');
    for (i = 0; i != sliders.length; i++) {
        var slider = sliders[i];
        initSliderSit(slider);
    }

    function initSliderSit(slider) {
        var parent = slider.parentNode;
        while (!parent.className.match(/th-slider-wrapper/)) {
            parent = parent.parentNode;
        }

        var navButtons = parent.querySelectorAll('.nav-buttons button');

        var s = new thSlider(slider, {
            draggable: true,
            scrollListener: true,
            scrollModeMaxWidth: 599,
            oninit: function (slider) {
                if (navButtons.length === 2) {
                    thSliderTools.onInitNav(slider, navButtons);
                }
            },
            onchange: function (slider) {
                if (navButtons.length > 0) {
                    thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
                }
            }
        });

    }
}

function parseCardSitClickOverlay() {
    
    function openOverlay(e) {
        e.preventDefault();

        var elem = $(this);

        var sit_url = elem.attr('href').split('?');
        var sit_url = sit_url[0] + '?overlay';

        var overlay_id = 'overlay-sit';

        var date = moment($('.dtstart', elem).attr('datetime'), "YYYY-MM-DD").format("Do MMMM");
        var h_from = $($('.hours-js span', elem).get(0)).text();
        var h_to = $($('.hours-js span', elem).get(1)).text();

        $('#' + overlay_id + ' .js-content-overlay-sit').html('');
        $('#' + overlay_id + ' .loader').removeClass('hide');
        $('#' + overlay_id + ' .bottom-part').addClass('hide');

        $.get(sit_url, function (responseHTML) {
            $('#' + overlay_id + ' .loader').addClass('hide');
            $('#' + overlay_id + ' .js-content-overlay-sit').html(responseHTML);
            $('#' + overlay_id + ' .js-content-overlay-sit .time').html($('time', elem).html());
            $('#' + overlay_id + ' .js-content-overlay-sit .floating-btn').attr('href', elem.attr('href'));
            $('#' + overlay_id + ' .bottom-part').removeClass('hide');
            $('#' + overlay_id + ' .date-js').text(date);

            if (!h_from && !h_to) {
                $('#' + overlay_id + ' .dt-schedule').addClass('hide');
            } else if (h_from && !h_to) {
                $('#' + overlay_id + ' .hours-js-from').removeClass('hide');
                $('#' + overlay_id + ' .hour-js-from').text(h_from);
            } else if (h_from && h_to) {
                $('#' + overlay_id + ' .hours-js-from-to').removeClass('hide');
                $('#' + overlay_id + ' .hour-js-from').text(h_from);
                $('#' + overlay_id + ' .hour-js-to').text(h_to);
            }
            var mapsContainer = document.querySelector('#' + overlay_id + ' .maps');
            if (mapsContainer) {
                th_maps.initSingleMap(mapsContainer);
            }
            setTimeout(function () {
                setSliderOverlaySit(overlay_id);
            }, 400);

            // Si on est dans une fiche appartenant au flux light (ces fiches ont la classe bottom-part parce qu'elles ont une barre dans le bas de l'overlay)
            if ($('#' + overlay_id + ' .js-flux-light').length > 0) {
                var currentFicheurl = sit_url;

                thGlobalDefaults.dataLayer = dataLayerFromContext;
                window.dataLayer.push(thGlobalDefaults.dataLayer);

                window.dataLayer.push({
                    'event': 'ajax_pageview',
                    'pagePath': currentFicheurl ? currentFicheurl : window.location.href,
                });

                if (wp_debug) {
                    console.log("url de la fiche consulté", currentFicheurl);
                    console.log("dataLayer", window.dataLayer);
                }
            }

        });
        // RGAA : On stock l'element qui a ouvert l'overlay pour y revenir a sa fermeture
        th_overlay.focusElOnClose[overlay_id] = this;
        th_overlay.open(overlay_id, true, true);
    }

    // Gestion de l'ouverture de l'overlay si on appuye sur la touche "space"
    var cardOverlay = $('.js-card-sit-overlay.card:not(.parsed)').add('.js-card-sit-overlay.btn');
    cardOverlay.on("keydown", function (event) {
        if (event.keyCode === 32) {
            event.preventDefault();
            $(this).trigger("click");
            $(this).addClass("parsed");
        }
    });

    // Gestion de l'ouverture de l'overlay au click
    cardOverlay.on('click', openOverlay).addClass('parsed');

}

parseCardSitClickOverlay();