/* Initialisation du slider */
function galerieOverlayemploi() {
    var sliders = document.querySelectorAll('.galerie-section .th-slider:not(.no-autoload)');
    for (i = 0; i != sliders.length; i++) {
        var slider = sliders[i];
        initSlider(slider);
    }


    function initSlider(slider) {
        var parent = slider.parentNode;
        while (!parent.className.match(/th-slider-wrapper/)) {
            parent = parent.parentNode;
        }

        var navButtons = parent.querySelectorAll('.nav-buttons button');

        var s = new thSlider(slider, {
            draggable: true,
            scrollListener: true,
            scrollModeMaxWidth: 1024,
            oninit: function (slider) {
                if (navButtons.length === 2) {
                    thSliderTools.onInitNav(slider, navButtons);
                }
            },
            onchange: function (slider) {
                if (navButtons.length > 0) {
                    thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
                }
            }
        });
    }


}

function relationOverlayemploi() {
    var sliders = document.querySelectorAll('.relation-section .th-slider:not(.no-autoload)');
    for (i = 0; i != sliders.length; i++) {
        var slider = sliders[i];
        initSlider(slider);
    }

    function initSlider(slider) {
        var parent = slider.parentNode;
        while (!parent.className.match(/th-slider-wrapper/)) {
            parent = parent.parentNode;
        }

        var navButtons = parent.querySelectorAll('.nav-buttons button');

        var s = new thSlider(slider, {
            draggable: true,
            scrollListener: true,
            scrollModeMaxWidth: 1024,
            oninit: function (slider) {
                if (navButtons.length === 2) {
                    thSliderTools.onInitNav(slider, navButtons);
                }
            },
            onchange: function (slider) {
                if (navButtons.length > 0) {
                    thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
                }
            }
        });
    }

}


// Fonction utilisée pour l'affichage des offres d'emploi dans l'overlay emploi info

function parseCardOffresEmploiClickOverlay() {

    $('.card-manche-emploi:not(.parsed)').on('click', function (e) {

        var elem = $(this);

        if ( elem.hasClass('premium') ) {

            e.preventDefault();

            var overlay_id = 'overlay-emploi-info';
            var sit_url = elem.attr('href');

            $('#' + overlay_id).addClass("overlay-offre-emploi");
            $('#' + overlay_id).find(".single-emploi").addClass("single-offre-emploi");

            $('#' + overlay_id + ' .js-content-overlay-emploi').html('');
            $('#' + overlay_id + ' .loader').removeClass('hide');

            $.get(sit_url, function (responseHTML) {

                $('#' + overlay_id + ' .loader').addClass('hide');
                $('#' + overlay_id + ' .js-content-overlay-emploi').html($('.single-offres', responseHTML).html());

                // Data Layer : injection de l'url de la fiche d'emploi consulté + titre de la fiche
                var currentFicheurl = sit_url;
                thGlobalDefaults.dataLayer = dataLayerFromContext;
                window.dataLayer.push(thGlobalDefaults.dataLayer);

                window.dataLayer.push({
                    'event': 'ajax_pageview',
                    'pagePath': currentFicheurl ? currentFicheurl : window.location.href,
                    'title': $('#' + overlay_id + ' .js-content-overlay-emploi .title-container .title-h2').text()
                });

                console.log("dataLayer", window.dataLayer);
                var mapsContainer = document.querySelector('#' + overlay_id + ' .maps');
                if (mapsContainer) {
                    th_maps.initSingleMap(mapsContainer);
                }

                frmRecaptcha();

                setTimeout(function () {
                    relationOverlayemploi(overlay_id);
                    galerieOverlayemploi(overlay_id);
                    formInputFileAndCheckbx();
                }, 400);

            });

            th_overlay.open(overlay_id, true, true);


            window.history.pushState({}, '', sit_url);

            $('body').on('click', '.btn-close[data-overlay-close=overlay-emploi-info]', function (e) {

                url = new URL(sit_url);
                path = url.pathname.split("/");
                path.pop();
                path.pop();
                url.pathname = path.join("/")
                console.log(url)

                window.history.pushState({}, '', url);

            });
        }

    }).addClass('parsed');

}

parseCardOffresEmploiClickOverlay();
