/* Initialisation du slider */
(function () {

    function openOverlayGalerie() {

        if (document.body.clientWidth > thConfig.tabletPaysageBreakpoint) {

            var sliders = document.querySelectorAll('.overlay-galerie .th-slider');

            for (i = 0; i != sliders.length; i++) {
                var slider = sliders[i];
                initSliderSimple(slider);
            }

            function initSliderSimple(slider) {
                var parent = slider.parentNode;
                while (!parent.className.match(/th-slider-wrapper/)) {
                    parent = parent.parentNode;
                }

                var navButtons = parent.querySelectorAll('.nav-buttons button');

                var s = new thSlider(slider, {
                    draggable: true,
                    scrollListener: true,
                    scrollModeMaxWidth: 980,
                    oninit: function (slider) {
                        if (navButtons.length === 2) {
                            thSliderTools.onInitNav(slider, navButtons);
                        }

                    },
                    onchange: function (slider) {
                        if (navButtons.length > 0) {
                            thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
                        }
                    }
                });

            }
        }
    }

    openOverlayGalerie();

    // Fonction utilisée pour gérer le retour au départ du slider
    function resetSlider(overlay) {
        if (overlay) {
            var thSlider = $("#" + overlay).find(".th-slider");
            thSlider.css("transform", "none");
        }
    }

    // A l'ouverture de l'overlay on re-initialise la librairie pour éviter des soucis
    th_overlay.addCallbackOpen(function (overlay) {
        if (overlay.startsWith("overlay-galerie")) {
            openOverlayGalerie();
        }
    });

    // A la fermeture de l'overlay on fait un reset du slider, pour le forcer à retourner dans la position de départ
    th_overlay.addCallbackClose(function (overlay) {
        if (overlay == "overlay-galerie") {
            resetSlider(overlay);
        }
    });

})();