var barreListingSIT = document.getElementById('barre-listing-sit');
var mobileDate = document.querySelector('.mobile-date');

if (barreListingSIT) {
    var body = document.querySelector(".page-template");
    var barreListingSITPosition = barreListingSIT.offsetTop;

    if (isTabletPortraitOrSmalller()) {
        window.onscroll = function () {
            stickyBarre();
        };

        function stickyBarre() {
            var res = barreListingSITPosition - document.documentElement.scrollTop;

            if (res <= 0) {
                body.classList.add("is-sticky");
                if (mobileDate) {
                    mobileDate.classList.remove("is-black");
                }
            } else {
                body.classList.remove("is-sticky");
                if (mobileDate) {
                    mobileDate.classList.add("is-black");
                }
            }
        }
    }


}