(function () {
    // Ajout d'une classe pour afficher ou masquer la liste des services.
// Toggle de la valeur de l'attribut aria-expanded pour l'accessibilité
    $(".btn-service-emploi").on("click", function (e) {
        $(this).toggleClass("services-are-visible");

        if ($(this).attr("aria-expanded") === "true") {
            $(this).attr("aria-expanded", false);
        } else {
            $(this).attr("aria-expanded", true);
        }
    });
})();


