$(document).ready(function () {

    var goToMenu = $('.list-skip-links li').first();
    var goToContent = $('.list-skip-links li').last();
    var mainElement = $("main");
    var btnMenu = $('.main-navigation .btn-menu');
    goToMenu.focus();

    // Si on appuye sur la touche "enter" du clavier on passe le focus au bouton d'ouverture du menu principal
    goToMenu.on('keydown', function (e) {
        if (e.keyCode == 13) {
            btnMenu.focus();
        }
    });

    goToContent.on('keydown', function (e) {
        if (e.keyCode == 13) {
            $([document.documentElement, document.body]).animate({
                scrollTop: mainElement.offset().top - 80
            }, 1000);
            mainElement.focus();
        }
    });


});