/*
 *   This content is licensed according to the W3C Software License at
 *   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
 *
 * ARIA Combobox Examples
 */

'use strict';

var aria = aria || {};

function searchLocation(searchString) {
    var results = [];

    for (var i = 0; i < places_autocomplete.length; i++) {
        var veggie = places_autocomplete[i][0].toLowerCase();
        if (veggie.indexOf(searchString.toLowerCase()) === 0) {
            results.push(places_autocomplete[i]);
        }
    }

    return results;
}

/**
 * @function onload
 * @description Initialize the combobox examples once the page has loaded
 */
window.addEventListener('load', function () {

    $('.js-commune-autocomplete').each(function () {
        var communesInput = $('.js-commune-autocomplete-input',this).get(0);
        var communesListe = $('.js-commune-autocomplete-list',this).get(0);

        if (communesInput && communesListe) {
            new aria.GridCombobox(
                communesInput,
                communesListe,
                searchLocation
            );
        }
    });
});
