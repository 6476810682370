(function () {


    // Script pour la gestion des onglets dans un système de tabs qui deviennent un accordéon en mobile

    var firstTab = $(".bloc-slider-thematique .tablist button:first-of-type");
    var tablistContainer = $(".bloc-slider-thematique .tablist");


    firstTab.on('click', function (e) {
        console.log(e.target);
        tablistContainer.toggleClass("show-tabs");
    });


    var sliderContainers = document.querySelectorAll(".bloc-slider-thematique");
    for (i = 0; i != sliderContainers.length; i++) {
        var sliderContainer = sliderContainers[i];
        initSliderThematique(sliderContainer);
    }

    function initSliderThematique(sliderContainer) {
        if (sliderContainer) {
            var navButtons = sliderContainer.querySelectorAll(".nav-buttons button");
            var customLinksWrapper = sliderContainer.querySelector('.nav-links');
            var slider = sliderContainer.querySelector(".th-slider");

            this.slider = new thSlider(slider, {
                scrollListener: true,
                draggable: true,
                scrollModeMaxWidth: 1199,
                oninit: function (slider) {

                    if (navButtons.length === 2) {
                        thSliderTools.onInitNav(slider, navButtons);
                    }

                    var customLinks = customLinksWrapper.querySelectorAll('button');

                    function goToSlide(currentElement) {
                        slider.goto(parseInt(currentElement.getAttribute('data-index')));
                    }

                    for (var i = 0; i < customLinks.length; i++) {
                        customLinks[i].addEventListener('click', function (e) {
                            goToSlide(e.target);
                        });
                        customLinks[i].addEventListener('focus', function (e) {
                            goToSlide(e.target);
                        });
                    }
                },
                onchange: function (slider) {
                    if (navButtons.length > 0) {
                        thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
                    }
                    if (customLinksWrapper) {
                        thSliderTools.onChangeDots(slider, customLinksWrapper);
                    }

                }
            });
        }
    }

})();