var blocCarteInteractive = {
    bloc: $(".bloc-carte-interactive"),
    slider: null,
    sliderWrapper: null,
    cards: null,
    map: null,
    mapZones: null,
    currentSlide: null,

    init: function () {
        this.cards = $(".slide", this.bloc);
        this.map = this.bloc.find(".svg-canvas");
        this.mapZones = this.map.find("#zones path");
        this.slides = this.bloc.find(".slide");
        this.initSlider();
        this.goToCard();
        this.hoverEffect();
    },

    initSlider: function () {
        if (this.bloc) {
            var sliderWrapper = this.bloc.find(".th-slider")[0];
            var _self = this;
            var navButtons = this.bloc.find(".nav-buttons button");


            if (sliderWrapper) {

                this.slider = new thSlider(sliderWrapper, {
                    draggable: true,
                    scrollListener: true,
                    scrollModeMaxWidth: 980,

                    oninit: function (sliderWrapper) {
                        _self.currentSlide = 0;
                        if (navButtons.length === 2) {
                            thSliderTools.onInitNav(sliderWrapper, navButtons);
                            thSliderTools.determineItemsVisiblesRGAA(sliderWrapper);
                        }
                        // Active le 1er hotspot à l'initialisation
                        _self.highlightCity(sliderWrapper.state.current);
                    },
                    beforechange: function (sliderWrapper) {
                        _self.highlightCity(sliderWrapper.state.current);
                    },
                    onchange: function (sliderWrapper) {
                        if (navButtons.length > 0) {
                            thSliderTools.determineItemsVisiblesRGAA(sliderWrapper);
                            thSliderTools.onChangeNavIgnoreLastItemIn(sliderWrapper, navButtons);
                        }
                    }
                });
            }
        }
    },
    highlightCity: function (city) {
        var _self = this;
        var currentSlide = $(this.slides[city]);
        var currentArea = $(currentSlide).data('area');

        $.each(this.mapZones, function () {

            if ($(this).attr('id') === currentArea) {
                $(this).addClass("is-active");
            } else {
                $(this).removeClass("is-active");
                $(this).blur();
            }
        });
    },

    goToCard: function () {

        var _self = this;

        $.each(this.mapZones, function (k, v) {
            var id = $(this).attr("id");

            $(this).on("click", function (e) {
                var singleCard = $('.slide[data-area="' + id + '"]');

                _self.slider.goto(parseInt(singleCard.index()));
            });

        });
    },

    hoverEffect: function () {
        let _self = this;


        this.mapZones.each(function () {

            let currentId = $(this).attr("id");

            let zones = ['zone5', 'zone8'];
            let points = ['#POI-6 .cls-8', "#POI-13 .cls-8"];

            for (let i = 0; i < zones.length; ++i) {

                if (currentId == zones[i]) {
                    // Au hover on change la couleur du dot décoratif
                    $(this).on("mouseover", function () {

                        _self.changeColor($(this), points[i]);
                    });

                    // On remet ça couleur originale au mouseleave
                    $(this).on("mouseleave", function () {
                        _self.backToOriginalColor($(this), points[i]);
                    });

                    // On change la couleur au click
                    $(this).on("click", function () {
                        $(this).off("mouseover");
                        $(this).off("mouseleave");
                        _self.changeColor($(this), points[i]);
                    });

                } else {
                    // Si on clique sur une zone qui n'est pas la courante, on change la couleur du dot
                    $(this).on("click", function () {
                        _self.backToOriginalColor($(this), points[i]);
                    });
                }
            }


            var zone5 = $("#zone5");
            var zone5point = $("#POI-6 .cls-8");
            var zone8 = $("#zone8");
            var zone8point = $("#POI-13 .cls-8");

            zone5.on("mouseenter", function () {
                zone5point.css("fill", "#000000");
            });

            zone8.on("mouseenter", function () {
                zone8point.css("fill", "#000000");
            });

        });
    },
    changeColor: function (el, currentPoint) {
        el.parents(".bloc-carte-interactive").find(currentPoint).css("fill", "#000000");
    },
    backToOriginalColor: function (el, currentPoint) {
        el.parents(".bloc-carte-interactive").find(currentPoint).css("fill", "#56ADA7");
    }

}

blocCarteInteractive.init();




