(function () {
    var btnMenu = $(".btn-menu-open-wrapper");
    var btnMenuCloseContainer = $(".btn-menu-close-wrapper")
    var barreNavigation = $("#me-barre-navigation");
    var menu = $('#overlay-menu-emploi');
    var menuPrincipalEmploi = $("#menu-principal-emploi");
    var menuContainer = menu.find('.inner-container');
    var overlayShadow = $("#overlay-shadow");
    var menuButtons = $(".main-navigation .btn-values-wrapper");
    var overlayMenuContainer = $("#overlay-menu-emploi .inner-container");
    var btnValues = $(".btn-values-wrapper button");
    var submenuParent = $(".submenu-parent");

    function menuIsOpen() {
        btnMenu.addClass("menu-is-open");
        btnMenuCloseContainer.addClass("show-btn-close")
        barreNavigation.addClass("menu-is-open");

        // Sur le site au scroll on ajoute une classe sur la barre de navigation pour la faire disparaitre de l'écran
        // Le souci est que dès fois au resize de l'écran, cette classe est declenché, ce qui cause des soucis d'affichage dans l'overlay menu
        //  A l'ouverture du menu on va donc empêcher le scroll

        document.addEventListener('scroll', function (e) {
            e.preventDefault();
            e.stopPropagation();
            return false;
        }, {passive: false});

        // Lorsque le menu est ouvert, au click sur le shadow on force le changement des classes des différents boutons
        overlayShadow.on("click", function () {
            menuIsClosed();
        });
    }

    function menuIsClosed() {
        btnMenu.removeClass("menu-is-open");
        btnMenuCloseContainer.removeClass("show-btn-close")
        barreNavigation.removeClass("menu-is-open");
        menuContainer.children().removeClass("show-menu");
        btnValues.first().removeClass("link-highlight");
        menuButtons.each(function () {
            $(this).find('button').removeClass('link-highlight');
        });
        btnValues.each(function () {
            $(this).attr("aria-expanded", false);
        });
    }

// Au déclenchement de l'ouverture et fermeture du menu
    th_overlay.addCallbackOpen(function (menu) {
        if (menu === "overlay-menu-emploi") {
            menuIsOpen();
            // Si à l'ouverture du menu on appuye sur la touche "ESCAPE" (échappe), on doit supprimer toutes les classes ajoutés avec la fonction menuIsOpen
            document.addEventListener('keydown', function (e) {
                if ($("#" + menu) > 0 && e.key === "Escape") {
                    e.preventDefault();
                    e.stopPropagation();
                    // on ferme le menu
                    menuIsClosed();
                }
            });
        }

    });

    th_overlay.addCallbackClose(function (menu) {
        if (menu === "overlay-menu-emploi") {
            menuIsClosed();
        }
    });

// Au rechargement de la page avec l'overlay encore ouvert, on a besoin de répéter la gestion des
// classes vu que le th_overlay callBackOpen et close ne marchent pas
    if (menu.hasClass("open")) {
        menuIsOpen();
    } else {
        menuIsClosed();
    }

// Au click sur un des boutons qui déclenchent l'ouverture du megamenu, on change l'attribut aria-expanded à "true"
    btnValues.each(function () {
        $(this).on("click", function () {
            $(this).attr("aria-expanded", true);
        });
    });

    var submenuLinks = menu.find(".submenu");
    submenuParent.each(function () {
        $(this).on("click", function () {
            submenuParent.each(function () {
                $(this).removeClass("selected-submenu");
            });
            $(this).addClass("selected-submenu");
            var current = $(this);
            submenuLinks.each(function () {
                if ($(this).attr("id") === current.attr("data-open-submenu")) {
                    $(this).addClass("show-submenu");
                    submenuLinks.not(this).removeClass("show-submenu");
                }
            });

        });
    });


// Gestion la version tablette et mobile de mega menu
    var repeatedLinks = menu.find(".btn-values-wrapper");
    var rappelMenu = menu.find(".rappel-menu");
    var bottomPart = menu.find(".bottom-part");
    var menuTopPart = menu.find(".top-part-menu");
    var btnBack = menu.find(".btn-back");
    var btnMenuClose = menu.find(".btn-menu-close");
    repeatedLinks.each(function () {
        if (isTabletPortraitOrSmalller()) {
            $(this).on("click", function (e) {
                // On va récupérer l'attribut href de l'élément sur lequel on a cliqué
                var linkAttr = $(this).find(".main-menu-button").attr("href");

                // Seulement si on a cliqué sur un bouton et pas un lien, on ajoute les bonnes classes et styles pour faire apparaitre le deuxième volet
                if (typeof linkAttr == 'undefined' || linkAttr === false) {
                    menuPrincipalEmploi.addClass("show-menu");
                    rappelMenu.add(menuTopPart).add(bottomPart).css("display", "none");
                    btnMenuClose.addClass("change-position");
                }

                // On va récupérer le texte contenu dans le lien cliqué pour l'injecter dans la barre qu'on trouve dans le deuxième volet en haut
                // (le rappel du lien sur lequel on a cliqué)

                var highlight = $(this).find(".highlight").text();
                var content = $(this).find(".content").text();

                var ancienHighlight = $(".highlight", menuPrincipalEmploi);
                ancienHighlight.text(highlight);
                var ancienContent = $(".content", menuPrincipalEmploi);
                ancienContent.text(content);

            });
        }
    });
    btnBack.add(btnMenuClose).on("click", function () {
        if (isTabletPortraitOrSmalller()) {
            $(this).parents(".main-menu").removeClass("show-menu");
            rappelMenu.add(menuTopPart).add(bottomPart).css("display", "flex");
            btnMenuClose.removeClass("change-position");
            overlayMenuContainer.children().removeClass("show-menu");
        }

    });


})();


