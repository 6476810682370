function checkboxesAria() {
    var allCheckboxes = $('input[type=checkbox]');

    allCheckboxes.each(function () {
        if ($(this).attr('checked') === 'checked') {
            $(this).attr('aria-checked', true);
        } else {
            $(this).attr('aria-checked', false);
        }

    });


    allCheckboxes.click(function () {

        if ($(this).attr('aria-checked') === 'true') {
            $(this).attr('aria-checked', false);
        } else {
            $(this).attr('aria-checked', true);
        }
    });
}

checkboxesAria();


$(document).ready(function () {

    var toggleSwitchFiltres = $('.group-switch input[type=checkbox]');


    // On ajoute par default la classe active sur la réponse non
    $('.frm_off_label').addClass('active-choice');

    toggleSwitchFiltres.on('click', function () {
        var ariaStatus = $(this).attr('aria-checked');

        // En se basant sur l'état checked/ unchecked de l'input on ajoute la classe "active-choice" sur le juste élement
        if (ariaStatus === 'false') {
            $(this).siblings('.frm_on_label').removeClass('active-choice');
            $(this).prev('.frm_off_label').addClass('active-choice');

        } else if (ariaStatus) {
            $(this).prev('.frm_off_label').removeClass('active-choice');
            $(this).siblings('.frm_on_label').addClass('active-choice');
        }
    });
});