(function () {

    // Initialisation du slider et de la carte
    if ($('.overlay-webcams').length > 0) {
        th_overlay.addCallbackOpen(function (overlayId) {
            if (overlayId == 'overlay-webcams') {
                setTimeout(function () {
                    initSlider(document.querySelector('.overlay-webcams .th-slider'));
                    th_maps.initSingleMap(document.querySelector('#webcams-listing'));

                }, 250);
            }
        });
    }

    // Fonction qui gère l'initialisation du slider
    function initSlider(slider) {
        var parent = slider.parentNode;
        while (!parent.className.match(/data-bottom/)) {
            parent = parent.parentNode;
        }

        var navButtons = parent.querySelectorAll('.nav-buttons button');
        var counterWrapper = parent.querySelector('.counter-wrapper');

        var s = new thSlider(slider, {
            draggable: true,
            scrollListener: true,
            scrollModeMaxWidth: '1024',
            oninit: function (slider) {

                if (navButtons.length === 2) {
                    thSliderTools.onInitNav(slider, navButtons);
                }
                if (counterWrapper) {
                    thSliderTools.onInitCounter(slider, counterWrapper);
                }

            },
            onchange: function (slider) {
                if (navButtons.length > 0) {
                    thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
                }
                if (counterWrapper) {
                    thSliderTools.onChangeCounter(slider, counterWrapper);
                }
            }
        });

    }

    var popup = $("#webcam-iframe-container");
    var popupBtnClose = $(".btn-close", popup);
    var popupContainer = $(".popup-container", popup);
    var popupIframe = $('iframe', popup);
    var popupShadow = $(".shadow", popup);
    var lastClickedCard = null;

    // Au click sur le btn close on ajoute la classe hide pour cacher la popup et on fait un reset de l'attribut src de l'iframe,
    // pour ne pas avoir un iframe toujours chargé en background
    popupBtnClose.add(popupShadow).on("click", function () {
        popup.addClass("hide");
        popupIframe.attr("src", "").appendTo(popupContainer);
        // Focus sur l'élément qui à déclenché l'ouverture de l'iframe
        lastClickedCard.focus();
    });


    // A l'ouverture de l'overlay webcams...

    th_overlay.addCallbackOpen(function (overlayId) {
        if (overlayId == "overlay-webcams") {

            // On crée une fonction qui va injecter l'iframe dans la popup, au click sur une card generé par Leaflet
            function openPopupFromMarker() {
                var mapMarker = $(".leaflet-marker-icon");
                mapMarker.each(function () {
                    $(this).attr("tabindex", "-1");
                    $(this).on("click", function () {

                        setTimeout(function () {
                            var popupCard = $(".leaflet-popup-content .card");
                            popupCard.on("click", function () {
                                addDataToIframe($(this));
                            });
                        }, 400);

                    });
                });
            }

            // On crée une fonction qui va injecter l'iframe dans la popup, au click sur une card dans le slider en bas
            function openPopupFromSlider() {
                var cards = $("#overlay-webcams .card");
                cards.each(function () {
                    $(this).on("click", function () {
                        addDataToIframe($(this));
                    });
                });
            }

            // Fonction qui gère la création de l'iframe (on injecte dans l'attribut src le lien choisi par le client)
            function addDataToIframe(currentcard) {
                var dataUrl = currentcard.attr("data-iframe-url") + "?autoplay=1";
                var cardTitle = currentcard.find(".title-card").text();

                popup.removeClass("hide");
                popupIframe.attr("src", dataUrl).appendTo(popupContainer);
                popupIframe.attr("title", 'Webcam' + ' ' + cardTitle);
                popupBtnClose.focus();
                th_rgaa_focus.init(popupContainer, popupBtnClose.get(0), popupBtnClose.get(0));
                lastClickedCard = currentcard;
            }

            openPopupFromSlider();

            // On a besoin d'un setTimeOut pour faire bien marcher la fonction openPopupFromMarker
            setTimeout(function () {
                openPopupFromMarker();
            }, 800);

        }
    });


})();

// Fonction qui gère la création de la carte
function callbackMapWebcamsSlider(macarte) {
    macarte.zoomControl.setPosition('topleft');

    var markers = [];
    $('.results .card', $(macarte._container).parents('.overlay')).each(function () {
        var id = this.getAttribute('data-id');

        var latlng = {lat: this.getAttribute('data-lat') * 1, lng: this.getAttribute('data-lng') * 1};
        if (latlng.lat != 0 && latlng.lng != 0) {
            var marker = th_maps.createMarker(macarte, latlng, 'default');

            macarte.on("click", function (e) {
                marker.setIcon(th_maps.markersIcons['default']);
            });


            // Lorsqu'on fait un hover sur une card, on change l'icône du marqueur
            $(this).on({
                mouseenter: function () {
                    marker.setIcon(th_maps.markersIcons['hover']);
                },
                mouseleave: function () {
                    marker.setIcon(th_maps.markersIcons['default']);
                }
            });

            th_maps.createInfoWindow(this.outerHTML, marker, 260);
            markers.push(marker);
        }
    });
    var group = new L.featureGroup(markers);
    macarte.fitBounds(group.getBounds());
    if (isTouchDevice()) {
        macarte.dragging.disable();
    }
}


