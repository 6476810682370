var th_anchor = {
    parse: function (el) {

        if (!el) {
            el = document;
        }

        $('[data-anchor]:not(.parsed)', el).each(function () {
            $(this).addClass('parsed');
            $(this).on('click', th_anchor.scroll);
        });
    },

    scroll: function (e) {
        e.preventDefault();
        var anchor = $(this).attr('data-anchor');
        if (anchor == 'scroll') {
            $('html, body').animate({scrollTop: ($('html,body').scrollTop() + 800 - 90)}, 'slow');
        } else {
            var offset = $(this.getAttribute('href')).offset();
            if (offset) {
                var scrollTop = offset.top;
                var add = parseInt(anchor);
                if (add > 0) {
                    scrollTop = scrollTop - add;
                    $('html, body').animate({scrollTop: scrollTop}, 'slow');
                }
            }
        }
        return false;
    }
};