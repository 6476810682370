var th_favoris = {

    requestUrl: '',

    data: {},
    $items: [],

    _map: {
        map: null,
        desktopMap: null,
        bounds: [],
        markers: {},
        popins: {},
        markerBounds: null,
        markerIcon: 'default',
        markerWidth: 300,

        timeoutUpdate: null,
    },

    _selector: {
        overlay: '#overlay-favoris',
        cardsContainer: '#panier-listing',
        map: '#map-favoris-desktop',
        nb_items: '.nb-items',
        btn_email: '.btn-email',
        popup: '.form-popup',
        input_popup: 'input[type=email]',
        btn_close_popup: '.close',
        btn_envoyer_popup: 'button[type=submit]',
    },

    classactive: 'added-to-favourites',

    callbacks: {
        afterUpdateListing: null,
        afterUpdate: null,
        clickMarker: null,
    },

    init: function (requestUrl) {

        th_favoris.requestUrl = requestUrl;
        th_favoris.parseItems();

        if (!th_favoris.testLocalstorage()) {
            th_favoris.desactivate();
            return false;
        }

        if (!th_favoris.load()) {
            th_favoris.save();
        }

        th_favoris.updateItems();
        th_favoris.addEvents();
        th_favoris.render();

        // RGAA - on fait un trap focus dans la popup interne
        th_favoris.trapFocusMail();

        th_favoris.map.init();
        th_favoris.map.update();

    },

    testLocalstorage: function () {
        var test = 'test';
        try {
            localStorage.setItem(test, test);
            localStorage.removeItem(test);
            return true;
        } catch (e) {
            return false;
        }
    },

    load: function () {
        var contentString = localStorage.getItem('th_favoris');
        if (contentString) {
            var contentJSON = $.parseJSON(contentString);
            if (contentJSON) {
                th_favoris.data = contentJSON;
                return true;
            }
        }
        return false;
    },

    save: function () {
        localStorage.setItem('th_favoris', JSON.stringify(th_favoris.data));
    },

    parseItems: function () {
        $('[data-addpanier]:not(.parsed)').each(function (index) {
            th_favoris.$items.push(this);
            $(this).addClass('parsed');
        })
        $('[data-addpanier]').each(function (index) {
            id = $(this).attr('data-addpanier');
            if (!$(this).hasClass(th_favoris.classactive) && th_favoris.data[id]) {
                th_favoris.activeItem(id);
            }
        })
        th_favoris.$items = $(th_favoris.$items);
    },

    updateItems: function () {
        $.each(th_favoris.data, function (id, d) {
            th_favoris.activeItem(id);
        })
    },

    desactivate: function () {
        $('#panier-disabled').addClass('disabled');
        th_favoris.$items.remove();
    },

    addEvents: function () {
        th_favoris.$items.on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            th_favoris.addItem(this);
        });
    },

    isInPanier: function (id) {
        if (th_favoris.data[id]) {
            return true;
        }
        return false;
    },

    activeItem: function (id) {
        $('[data-addpanier="' + id + '"]').addClass(th_favoris.classactive);
        $('[data-addpanier="' + id + '"] span').addClass('icon-ico-heart-fill');
    },

    unActiveItem: function (id) {
        $('[data-addpanier="' + id + '"]').removeClass(th_favoris.classactive);
        $('[data-addpanier="' + id + '"] span').removeClass('icon-ico-heart-fill');
    },

    addItem: function (el) {

        var item = {
            html: '',
            id: ''
        };

        item.id = $(el).data('addpanier');

        if (th_favoris.isInPanier(item.id)) {
            th_favoris.removeItem(item.id);
            th_favoris.map.update();
            return false;
        }

        th_favoris.activeItem(item.id);

        $.get(th_favoris.requestUrl, {id: item.id}, function (dataItem) {
            if (dataItem) {
                if (!dataItem.error) {
                    item = dataItem;
                    th_favoris.data[item.id] = item;
                    th_favoris.save();
                    th_favoris.render();
                    th_favoris.map.update();
                }
            }
        }, 'json');

        return true;
    },

    removeItem: function (id) {
        th_favoris.unActiveItem(id);
        delete th_favoris.data[id];
        th_favoris.save();
        th_favoris.render();
    },

    render: function () {
        $(th_favoris._selector.cardsContainer).html('');

        var mailContent = '';
        var ids = [];
        var nb_items = 0;

        $.each(th_favoris.data, function (k, item) {
            $(th_favoris._selector.cardsContainer).append(item.html);
            mailContent = mailContent + '<li><a href="' + item.link + '">' + item.title + '</a><br/></li>';
            th_favoris.activeItem(item.id);
            ids.push(item.id);
            nb_items++;
        });

        // Hack : déplacement du bouton favoris hors de l'image pour le transformer ensuite en bouton "supprimer" en CSS
        var cards = $(th_favoris._selector.cardsContainer).find('.card');
        $.each($(cards), function (k, card) {
            var btn_fav = $(card).find('.btn-favoris');
            $(btn_fav).prependTo(card);     // déplace le bouton à la racine de la card
        });


        if (mailContent) {
            mailContent = '<ul>' + mailContent + '</ul>';
        }

        $('#field_mail_fav_list').val(mailContent);

        // Lazyload
        var lazyLoadInstance = new LazyLoad({
            elements_selector: ".lazy"
        });


        if (nb_items > 0) {
            $(th_favoris._selector.nb_items).parent().removeClass('empty');
            $(th_favoris._selector.nb_items).text(nb_items);
        } else {
            $(th_favoris._selector.cardsContainer).html('<p class="no-results">' + $(th_favoris._selector.cardsContainer).data('emptycartmsg') + '</p>');
            $(th_favoris._selector.nb_items).text('');
            $(th_favoris._selector.nb_items).parent().addClass('empty');
        }

        $('.download-panier-js').attr('href', th_favoris.requestUrl + '?ids=' + ids.join(';') + '&format=pdf');
        $('.mail-panier-js').on("click", function (e) {
            e.preventDefault();
            $('.form-popup', th_favoris._selector.overlay).addClass("open");
            $('.favoris-container', th_favoris._selector.overlay).addClass("open-popup");
            $('input[type="email"]').focus();
        });
        $('#overlay-favoris .close').on("click", function () {
            $('#overlay-favoris .close').addClass('bip');
            $('#overlay-favoris .form-popup').removeClass("open");
            $('.favoris-container', th_favoris._selector.overlay).removeClass("open-popup");
        });


        th_favoris.emptyFavoris();

        $(th_favoris._selector.cardsContainer + ' [data-addpanier]').on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            th_favoris.addItem(this);
        });
    },

    emptyFavoris: function () {
        $('.empty-panier').on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();

            $.each(th_favoris.data, function (k, item) {
                th_favoris.removeItem(item.id);
            });

            $('#panier-listing').html('');
            $(th_favoris._selector.nb_items).text('0');
            $(th_favoris._selector.nb_items).addClass('empty');
            $('#panier-listing').html('<p class="aligncenter">' + $('#panier-listing').data('emptycartmsg') + '</p>');

            th_favoris.map.update();
        })
    },

    trapFocusMail: function () {
        $(th_favoris._selector.btn_email).on("click", function () {
            var el = $(th_favoris._selector.popup);
            setTimeout(function () {
                $(th_favoris._selector.btn_close_popup).focus();
            }, 250);
            // À l'ouverture de la popup on fait un trap focus
            th_rgaa_focus.init(el);
            $(th_favoris._selector.btn_close_popup).on("click", function () {
                $(th_favoris._selector.btn_email).focus();
                var el = $(th_favoris._selector.overlay);
                var firstEl = $(".btn-close", el)[0];
                var lastEl = $('.btn-email', el)[0];
                // On remet le focus là où il était, sinon on va pas avoir le trap focus dans l'overlay des favoris
                th_rgaa_focus.init(el, firstEl, lastEl);


            });
        });
    },

    map: {

        init: function () {
            if (!th_favoris._map.map) {
                th_favoris.map.initMap();
                th_favoris.map.events();
            }
        },

        initMap: function () {
            th_overlay.addCallbackOpen(function (overlayId) {
                if (overlayId == 'overlay-favoris') {
                    if (document.querySelector(th_favoris._selector.map)) {
                        if (th_favoris._map.desktopMap == null) {
                            th_favoris._map.desktopMap = th_maps.initSingleMap(document.querySelector(th_favoris._selector.map));
                            th_favoris.map.set(th_favoris._map.desktopMap, false);
                        }
                    }
                }
            });

        },

        events: function (is_mobile) {
            var isInit = false;

            window.addEventListener("orientationchange", function () {
                if (!isInit) {
                    th_favoris.map.initMap(!is_mobile);
                } else {
                    if (window.clientWidth > 980) {
                        th_favoris.map.set(th_favoris._map.map, true);
                    } else {
                        th_favoris.map.set(th_favoris._map.map, false);
                    }
                }

                th_favoris.map.update();
            });
        },

        set: function (map, isMobile) {
            th_favoris._map.map = map;

            if (isMobile) {
                th_favoris._map.isMobile = isMobile;
            }
        },

        update: function () {

            var cards = th_favoris.listing.getCards();

            clearTimeout(th_favoris._map.timeoutUpdate);
            if (!th_favoris._map.map) {
                setTimeout(function () {
                    th_favoris.map.update(cards);
                }, 500);
                return false;
            }

            th_favoris.map.hideCluster();

            th_favoris._map.map._layersMaxZoom = 19;
            th_favoris._map.cluster = L.layerGroup();

            $.each(cards, function (k, card) {
                th_favoris._map.cluster.addLayer(th_favoris.map.getMarker(card));
            });

            th_favoris._map.markerBounds = L.latLngBounds(th_favoris._map.bounds);

            th_favoris._map.map.addLayer(th_favoris._map.cluster);


            setTimeout(function () {
                th_favoris._map.map.invalidateSize();

                if (!th_favoris._map.isMobile
                    && th_favoris._map.markerBounds
                    && Object.keys(th_favoris._map.markerBounds).length > 0
                    && th_favoris._map.markerBounds.constructor === Object
                ) {
                    th_favoris._map.map.fitBounds(th_favoris._map.markerBounds, {padding: [25, 25]});
                    zoom = th_favoris._map.map.getZoom();
                    if (zoom > 16) {
                        th_favoris._map.map.setZoom(16);
                    }
                }

                if (th_favoris.callbacks.mapUpdate) {
                    th_favoris.callbacks.mapUpdate(th_favoris._map.map);
                }

            }, 250);
        },

        getMarker: function (card) {
            if (!th_favoris._map.markers[card.id]) {
                th_favoris.map.createMarker(card);
            }
            return th_favoris._map.markers[card.id];
        },
        createMarker: function (card) {
            th_favoris._map.markers[card.id] = th_maps.createMarker(th_favoris._map.map, {
                lat: card.lat,
                lng: card.lng
            }, th_favoris._map.markerIcon);
            th_favoris._map.markers[card.id]._icon.setAttribute("tabindex", "-1")
            th_favoris._map.markers[card.id].on('click', function () {
                if (th_favoris.callbacks.onClickMarker) {
                    th_favoris.callbacks.onClickMarker(card.id, th_favoris._map.markers[card.id], card);
                }
            });

            // changement d'icon à l'ouverture et la fermeture de la popup
            th_favoris._map.markers[card.id].on('popupopen', function () {
                th_favoris._map.markers[card.id].setIcon(th_maps.markersIcons['hover']);
            });
            th_favoris._map.markers[card.id].on('popupclose', function () {
                th_favoris._map.markers[card.id].setIcon(th_maps.markersIcons['default']);
            });

            // changement d'icon au survol du marker
            // th_favoris._map.markers[card.id].on('mouseover', function () {
            //     th_favoris._map.markers[card.id].setIcon(th_maps.markersIcons['hover']);
            // });
            // th_favoris._map.markers[card.id].on('mouseout', function () {
            //     th_favoris._map.markers[card.id].setIcon(th_maps.markersIcons['default']);
            // });

            th_favoris._map.bounds.push(th_favoris._map.markers[card.id].getLatLng());

            if (!th_favoris._map.isMobile) {
                card_html = card.html;
                card_html = card_html.replace('src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7', '');
                th_favoris._map.popins[card.id] = th_maps.createInfoWindow(card_html, th_favoris._map.markers[card.id], th_favoris._map.markerWidth);
            }
        },

        hideCluster: function () {
            if (!th_favoris._map.map) {
                return null;
            }

            if (th_favoris._map.cluster) {
                th_favoris._map.map.removeLayer(th_favoris._map.cluster);
            }
        }
    },

    listing: {
        getCards: function () {

            var cards = {};
            $('.card,.card-manche-emploi', th_favoris._selector.cardsContainer).each(function () {
                var lat = this.getAttribute('data-lat') * 1;
                var lng = this.getAttribute('data-lng') * 1;
                if (lat != 0 && lng != 0) {

                    var card = {
                        id: this.getAttribute('data-id'),
                        lat: lat,
                        lng: lng,
                        html: this.outerHTML.replace('data-src', 'src').replace('lazy', ''),
                    };

                    cards[this.getAttribute('data-id')] = card;
                }
            });

            $('.card-inline').each(function () {
                var id = this.getAttribute('data-id');

                $(this).on({
                    mouseenter: function () {
                        th_favoris._map.markers[id].setIcon(th_maps.markersIcons['hover']);
                    },
                    mouseleave: function () {
                        th_favoris._map.markers[id].setIcon(th_maps.markersIcons['default']);
                    }
                });
            });

            delete cards.null;

            return cards;
        },
    }

};


var urlRequest = template_path + '/components/Favoris/ajax/favoris.php';

if (typeof translations !== 'undefined') {
    th_favoris.init(urlRequest);
} else {
    setTimeout(function () {
        th_favoris.init(urlRequest);
    }, 300);
}

