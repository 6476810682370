(function () {
    // Empêche l'exécution quand dans un autre thème pour palier à l'absence de surcharge des composants
    const barreNavigationGroupe = document.querySelector('.js-barre-navigation-groupe');
    if (barreNavigationGroupe) return;


// Gestion de l'accordeon avec le sidemenu à droite
    var btnServices = $("#sidemenu-btn");
    var toggled = false;
    btnServices.on("click", function () {
        if (toggled === false) {
            $(this).addClass("open-accordeon");
            toggled = !toggled;
        } else {
            $(this).removeClass("open-accordeon");
            toggled = !toggled;
        }

        $(this).attr('aria-expanded', toggled ? 'true' : 'false');
    });

// Gestion de l'affichage du bon menu dans l'overlay menu selon le bouton du menu principal choisi
// EX: Si j'ai cliqué sur "audace" je dois afficher le menu audace et cacher les autres 3 dans l'overlay menu
    var containerLiens = $(".btn-values-wrapper");
    var overlayMenuContainer = $("#overlay-menu .inner-container");

    containerLiens.each(function (e) {

        /* Débyt de l'ajout et de la suppression de la classe "link-highlight" qui est
        ajouté au bouton couramment choisi dans le menu principal */
        var buttonMenu = $(this).find('.main-menu-button');
        var current = $(this);

        buttonMenu.on("click", function (e) {
            containerLiens.each(function () {
                $(this).find("button").removeClass("link-highlight");
            })
            $(this).addClass("link-highlight");
            /* Fin de la gestion du link-highlight */

            var menuIdToShow = "#" + $(this).attr("data-menu");
            overlayMenuContainer.children().not(menuIdToShow).removeClass("show-menu");
            overlayMenuContainer.find(menuIdToShow).addClass("show-menu");
        });
    });

// Au click sur le bouton d'ouverture du menu, on affiche par défaut le menu maritimité
    $(".btn-menu").on("click", function () {
        if (!isTabletPortraitOrSmalller()) {
            overlayMenuContainer.find("#menu-principal-1").addClass("show-menu");
        }
    });
    
})();

