// Languages selector
if (isTouchDevice()) {
    $('.lang').addClass('is-display');
    $('.lang-dropdown > a').on("click", function (e) {
        e.preventDefault();
        $(this).next().toggleClass("open");
    });
}

if (isIE()) {
    document.getElementsByTagName('body')[0].className += ' ie';
}

if (isNoHover()) {
    document.getElementsByTagName('body')[0].className += ' no-hover';
}

th_accordeon.init();
th_overlay.init();
th_expand.parse();
th_ancres.init();
th_anchor.parse();
thVheight.init();

// affichage des overlays possible que a partie de 2s
setTimeout(function () {
    $('body').addClass('overlay-load');
}, 1000);

// Lazyload
var lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy"
});

// Lancement du script Librairie Zoombox
$('.zoombox').zoombox();


/* Detect the scroll of the page down */
document.addEventListener('scroll', function () {
    var st = $(this).scrollTop();

    if (st > 100) {
        $("body").addClass("is-scrolled");
    } else {
        $("body").removeClass("is-scrolled");
    }
});


document.addEventListener('scroll', function () {
    var test = $(this).scrollTop();

    if (test >= 0) {
        $("body").addClass("is-scrolled-test");
    } else {
        $("body").removeClass("is-scrolled-test");
    }
});

/* Detect the scroll of the page up */
var lastscrolltop = 0;
var lastIsDirTop = 0;
document.addEventListener('scroll', function () {
    var st = $(document).scrollTop();
    if (st < lastscrolltop && lastIsDirTop == 0) {
        lastIsDirTop = 1;
        $(document.body).addClass('scrolldir-top', true);
    }
    if (st > lastscrolltop && lastIsDirTop == 1) {
        lastIsDirTop = 0;
        $(document.body).removeClass('scrolldir-top', true);
    }
    lastscrolltop = st;
});

$('a figcaption').on('touchstart click', function (e) {
    e.stopPropagation();
    e.preventDefault();
});


// Changement de l'affichage du bouton favoris au click ou lorsqu'on appuye sur la touche "ENTER" (13)
function addToFavourites(e, btnFavoris) {
    e.stopPropagation();
    e.preventDefault();
    btnFavoris.toggleClass("added-to-favourites");
}

$(".btn-favoris").on("click", function (e) {
    addToFavourites(e, $(this));
});

$(".btn-favoris").on("keydown", function (e) {
    if (e.which == 13 || e.which == 32) {
        addToFavourites(e, $(this));
    }
});


th_overlay.addCallbackOpen(function (overlayId) {
    if (overlayId == "overlay-sit") {
        $(document).ready(function () {
            $("#overlay-sit .btn-telephone").on("click", function () {
                $(this).toggleClass("activeTooltip");
            });
        });
    }
});



// Scroll vers une ancre
// ==========================================================================

(function () {
    const nodes = document.querySelectorAll('.js-scroll-to');

    nodes.forEach( (el) => {
        el.addEventListener('click', function (e) {
            e.preventDefault();
            const hash = this.hash;
            const target = document.querySelector(hash);

            if(target) {
                const header = document.querySelector('#barre-navigation');

                // Y offset pour le sticky header
                const offset = (header) ? header.offsetHeight : 0;
                const targetTop = window.scrollY + target.getBoundingClientRect().top - offset - 20;

                $('html, body').animate({
                    scrollTop: targetTop
                }, 800);
            }
        });
    })
})();
