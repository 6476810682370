th_maps.onLoad(function () {

    th_maps.addThemes("default", {
        accessToken: "",
        // style: 'https://api.maptiler.com/maps/topo-v2/style.json?key=QqtpaEdSDG9y5phpsBRq'
        style: 'https://api.maptiler.com/maps/openstreetmap/style.json?key=QqtpaEdSDG9y5phpsBRq'
    });

    th_maps.addMarkerIcon('default', {
        iconUrl: '' + document.location.origin + '/wp-content/themes/attitudemanche/assets/images/markers/ico-marker-map-2x.png',
        shadowUrl: null,
        iconSize: [40, 40],
        iconAnchor: [20, 20]
    });

    th_maps.addMarkerIcon('hover', {
        iconUrl: '' + document.location.origin + '/wp-content/themes/attitudemanche/assets/images/markers/ico-marker-map-hover-2x.png',
        shadowUrl: null,
        iconSize: [40, 40],
        iconAnchor: [20, 20]
    });

});

th_maps.init(thConfig.map.init);