var maree = {

    intervalList: [],

    clear: function () {
        $.each(maree.intervalList, function (k, interval) {
            clearInterval(interval);
        })
        maree.intervalList = [];
    },

    init: function () {

        maree.clear();

        $('[data-maree]').each(function () {

            var _self = this;

            var now = new Date();

            var month = now.getMonth() + 1;
            var day = now.getDate();
            // if(month <= 9){ month = '0'+month; }
            // if(day <= 9){ day = '0'+day; }
            var year = now.getFullYear();
            var hour = now.getHours();
            var min = now.getMinutes();
            var date = year + '-' + month + '-' + day + ' ' + hour + ':' + min;

            maree.get(['now', date], function (data) {
                if (data) {
                    maree.displayData(_self, data);

                    maree.intervalList.push(setInterval(function () {
                        maree.displayData(_self, data);
                    }, 60000));
                }
            });

        });
    },

    get: function (data, callback) {
        $.get(template_path + '/components/Marees/ajax/maree.php', {type: data[0], date: data[1]}, function (result) {
            if (result.success == 1) {
                callback(result.data);
            }
        }, 'json');
    },

    getFromTo: function (data, callback) {
        $.get(document.location.href, {type: data[0], from: data[1], to: data[2]}, function (result) {
            callback(result);
        });
    },


    displayData: function (el, data) {

        data['dateTime'] = data['dateTime'].replace(/-/g, '/');

        var nextTime = new Date(data['dateTime']);

        var currData = data;

        var time = (nextTime.getTime()) - (new Date().getTime());

        var hour = Math.floor(time / 3600000);
        time -= hour * 3600000;
        var min = Math.ceil(time / 60000);
        if (min <= 9) {
            min = '0' + min;
        }
        // console.log(min);
        if (min == 60) {
            hour++;
            min = 0;
        }
        // if(hour <= 9){
        //     hour = '0'+hour;
        // }

        $('.time', el).html(hour + 'h ' + min + 'm');

        if (currData[3]) {
            $('.coef-parent.hide', el).removeClass('hide');
            $('.coef', el).html(currData[3]);
        } else {
            $('.coef-parent:not(.hide)', el).addClass('hide');
        }

        if (currData['state'] == 'low') {
            $('.icon-maree', el).addClass('icon-maree-basse');
        } else if (currData['state'] == 'high') {
            $('.icon-maree', el).addClass('icon-maree-haute');
        } else {
            $('.icon-maree', el).classList('icon-maree');
        }

        $('.low,.high,.none', el).hide();
        $('.' + currData['state'], el).show();

    }

};

maree.init();


var groupes_maree_input_dates = $('#maree_datepicker');

if (groupes_maree_input_dates) {

    var calendarDateFormatMaree = 'DD/MM/YYYY';
    var siteLanguage = $('html').attr('lang');
    var calendarDateLanguageMaree = siteLanguage.substr(0, siteLanguage.indexOf('-'));

    // var $InputFromMaree = $('[data-jsdatestatus="from"]', groupes_input_dates);
    // var $InputToMaree = $('[data-jsdatestatus="to"]', groupes_input_dates);

    $('input.date', groupes_maree_input_dates).each(function () {
        var container = '.js-wrapper-dates-maree .container';
        var inline = false;

        if (!isTouchDevice()) {
            $(this).attr('type', 'text');
            $(this).attr('placeholder', '');
            $(this).dateRangePicker({
                inline: inline,
                singleDate: false,
                alwaysOpen: false,
                singleMonth: false,
                showShortcuts: false,
                showTopbar: false,
                monthSelect: true,
                hoveringTooltip: false,
                selectForward: false,
                yearSelect: [2022, 2030],
                startOfWeek: 'monday',
                startDate: startDate,
                customArrowPrevSymbol: '<span class="icon-ico-arrow-left-slider"></span>',
                customArrowNextSymbol: '<span class="icon-ico-arrow-right-slider"></span>',
                format: calendarDateFormatMaree,
                language: calendarDateLanguageMaree,
                container: container,
                separator: ' to ',
                getValue: function () {
                    if ($('[data-jsdatestatus="from"]').val() && $('[data-jsdatestatus="to"]').val())
                        return $('[data-jsdatestatus="from"]').val() + ' to ' + $('[data-jsdatestatus="to"]').val();
                    else
                        return '';
                },
                setValue: function (s, s1, s2) {
                    $('[data-jsdatestatus="from"]').val(s1);
                    $('[data-jsdatestatus="to"]').val(s2);
                },
            }).bind('datepicker-open', function (event, obj) {
                $('[data-jsdatestatus="from"]').parent('.wrapper-date').addClass('focus');
            }).bind('datepicker-first-date-selected', function (event, obj) {
                $('[data-jsdatestatus="from"]').parent('.wrapper-date').removeClass('focus');
                $('[data-jsdatestatus="to"]').parent('.wrapper-date').addClass('focus');
            }).bind('datepicker-close', function (event, obj) {
                $('[data-jsdatestatus="to"]').parent('.wrapper-date').removeClass('focus');
                $('[data-jsdatestatus="from"]').parent('.wrapper-date').removeClass('focus');

                if ($('[data-jsdatestatus="to"]').val() && $('[data-jsdatestatus="from"]').val()) {

                    $('#tabpanel-1, #tabpanel-3, #tabpanel-3').html('');
                    $('#overlay-marees .loader').removeClass('hide');

                    maree.getFromTo(['maree', $('[data-jsdatestatus="from"]').val(), $('[data-jsdatestatus="to"]').val()], function (html) {
                        if (html) {
                            $('#overlay-marees .loader').addClass('hide');
                            maree_html_tab1 = $('#tabpanel-1', html).html();
                            if (maree_html_tab1 != '') {
                                $('#tabpanel-1').html(maree_html_tab1);
                            }
                            maree_html_tab2 = $('#tabpanel-2', html).html();
                            if (maree_html_tab2 != '') {
                                $('#tabpanel-2').html(maree_html_tab2);
                            }
                            maree_html_tab3 = $('#tabpanel-3', html).html();
                            if (maree_html_tab3 != '') {
                                $('#tabpanel-3').html(maree_html_tab3);
                            }
                        }
                    });


                }
            });
        } else {
            var data_date = $(this).val();
            data_date = data_date.split('/').reverse().join('-');
            $(this).val(data_date);
            $(this).attr('type', 'date');

            $(this).on('change', function (eventObject) {
                var date_status = $(this).data('jsdatestatus');
                $('[data-jsdatestatus="' + date_status + '"]', '#barre-filtres [data-jsgroupdate="' + groupes_input_dates + '"]').val($(this).val());
            })
        }
    });
}
