function callbackMapEntreprise(macarte) {
    macarte.zoomControl.setPosition('topleft');
}

function parseCardEntrepriseClickOverlay() {
    $('.card-entreprise').on('click', function (e) {

        e.preventDefault();

        var elem = $(this);

        var sit_url = elem.attr('href');
        var overlay_id = 'overlay-emploi-info';

        if ($(this).hasClass("cabinet")) {
            $('#' + overlay_id).find(".single-emploi").addClass("single-cabinet");
        } else if ($(this).hasClass("entreprises")) {
            $('#' + overlay_id).find(".single-emploi").addClass("single-entreprise");
        }

        $('#' + overlay_id + ' .js-content-overlay-emploi').html('');
        $('#' + overlay_id + ' .loader').removeClass('hide');
        $('#' + overlay_id + ' .bottom-part').addClass('hide');

        $.get(sit_url, function (responseHTML) {

            var content_overlay = $(responseHTML).find('.js-content-overlay-emploi').html();

            // console.log("response", $(responseHTML).text());

            $('#' + overlay_id + ' .loader').addClass('hide');
            $('#' + overlay_id + ' .js-content-overlay-emploi').html(content_overlay);

            var mapsContainer = document.querySelector('#' + overlay_id + ' .maps');
            if (mapsContainer) {
                th_maps.initSingleMap(mapsContainer);
            }
            setTimeout(function () {
                setSliderOverlaySit(overlay_id);

                th_video.init('.bloc-video');

                th_favoris.parseItems();
                th_favoris.addEvents();

                // on relance le lazyload sur les images
                var lazyLoadInstance = new LazyLoad({
                    elements_selector: ".lazy"
                });

            }, 400);


            // Une fois chargé l'overlay on envoye l'évènement page view dans le data layer

            var currentFicheurl = sit_url;
            thGlobalDefaults.dataLayer = dataLayerFromContext;
            window.dataLayer.push(thGlobalDefaults.dataLayer);

            window.dataLayer.push({
                'event': 'ajax_pageview',
                'pagePath': currentFicheurl ? currentFicheurl : window.location.href,
                'title': $('#' + overlay_id + ' .js-content-overlay-emploi .title-container .title-h2').text()
            });

            console.log("url de la fiche consulté", currentFicheurl);
            console.log("dataLayer", window.dataLayer);

        });

        th_overlay.open(overlay_id, true, true);

        window.history.pushState({}, '', sit_url);


    }).addClass('parsed');


}

parseCardEntrepriseClickOverlay();


// On ajoute une classe pour changer de position le label ou le cacher lorsqu'on on commence à taper dans le champs

$(".barre-listing-emploi input").each(function () {
    $(this).on("input", function () {
        if ($(this).val() != "") {
            $(this).parent().addClass("has-content");
        } else {
            $(this).parent().removeClass("has-content");
        }
    });
});

if($('.post-type-archive-offreemploi').length > 0 ) {
    addRecaptchaScriptBody();
}

