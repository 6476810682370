var thConfig = {

    tabletPortraitBreakpoint: 980,
    tabletPaysageBreakpoint: 1024,
    desktopBreakpoint: 1280,
    mobileBreakpoint: 600,

    tagmanager: thGlobalDefaults.tagmanager,

    debug: true,

    // MAP IGN - 6mfxn9af26z1kmx9a9jwcpng / choisirgeoportail
    // Fond de carte Maptiler - Version Topo
    map: {
        init: {
            maps_class: '.maps:not(.no-autoload):not(.initialized)',
            tileLayerUrl: "https://api.maptiler.com/maps/openstreetmap/256/{z}/{x}/{y}@2x.jpg?key=QqtpaEdSDG9y5phpsBRq",
            tileLayerOptions: {
                attribution: "\u003ca href=\"https://www.maptiler.com/copyright/\" target=\"_blank\"\u003e\u0026copy; MapTiler\u003c/a\u003e \u003ca href=\"https://www.openstreetmap.org/copyright\" target=\"_blank\"\u003e\u0026copy; OpenStreetMap contributors\u003c/a\u003e",
                maxZoom: 18,
                crossOrigin: true,
            }
        },
        kmlBgColor: '00FFFFFF',
        kmlWeight: 2,
        kmlOpacity: 1,
        kmlColor: '#000',
        kmlBgOpacity: 0.0,
    }

};

if (typeof jsConfig == 'object') {
    for (var key in jsConfig) {
        if (typeof jsConfig[key] === 'object') {
            for (var subkey in jsConfig[key]) {
                thConfig[key][subkey] = jsConfig[key][subkey];
            }
        } else {
            thConfig[key] = jsConfig[key];
        }
    }
}