(function () {
    if (isHigherThanTabletPortrait()) {
        // Gestion de l'accordeon avec le sidemenu à droite
        var btnServices = $("#sidemenu-btn");
        var toggled = false;
        btnServices.on("click", function () {
            $(this).toggleClass("open-accordeon");
            toggled = !toggled;

            $(this).attr('aria-expanded', toggled ? 'true' : 'false');
        });

        // Gestion de l'affichage du bon menu dans l'overlay menu selon le bouton du menu principal choisi
        // EX: Si j'ai cliqué sur "audace" je dois afficher le menu audace et cacher les autres 3 dans l'overlay menu
        var containerLiens = $(".btn-values-wrapper");
        var overlayMenuContainer = $("#overlay-menu-emploi .inner-container");

        containerLiens.each(function (e) {

            /* Débyt de l'ajout et de la suppression de la classe "link-highlight" qui est
            ajouté au bouton couramment choisi dans le menu principal */
            var buttonMenu = $(this).find('.main-menu-button');
            var current = $(this);

            buttonMenu.on("click", function (e) {
                containerLiens.each(function () {
                    $(this).removeClass("link-highlight");
                })
                $(this).addClass("link-highlight");
                var submenuToOpen = $(this).attr("data-open-submenu");

                overlayMenuContainer.find("[data-open-submenu='" + submenuToOpen + "']").addClass("selected-submenu");

                /* Fin de la gestion du link-highlight */
                overlayMenuContainer.find("#menu-principal-emploi").addClass("show-menu");
            });

        });

        // Au click sur le bouton d'ouverture du menu, on affiche par défaut le menu maritimité

        $(".btn-menu").on("click", function () {
            if (!isTabletPortraitOrSmalller()) {
                overlayMenuContainer.find("#menu-principal-emploi").addClass("show-menu");
            }
        });
    }

})();


