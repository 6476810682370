// Smooth scroll vers la carte - au click sur la miniature de la carte dans la barre sit

$(document).ready(function () {

    $("#carte-anchor").on('click', function (event) {
        if (this.hash !== "") {
            event.preventDefault();
            var hash = this.hash;

            $('html, body').animate({
                scrollTop: $(hash).offset().top
            }, 800, function () {
                window.location.hash = hash;
            });
        }
    });
});