function formInputFileAndCheckbx() {
    $('input[type="file"]', $(".bloc-form")).addClass('jfilestyle');

    var inputsCheckbox = $(".bloc-form input[type=checkbox]");

    inputsCheckbox.each(function () {
        $(this).on("click", function () {
            $('this').parents('.frm_form_field').toggleClass("input-is-clicked");
        })
    });

    var inputFile = $('.bloc-form input[type="file"]');

    inputFile.on("focus", function () {
        $(this).parents('.frm_dropzone').addClass("input-is-focused");
    });


    inputFile.focusout(function () {
        $(this).parents('.frm_dropzone').removeClass("input-is-focused");
    });


    inputFile.change(function (e) {
        var filename = "";

        var files = $(this)[0].files;

        for (var i = 0; i < files.length; i++) {
            filename += [i + 1] + " - " + files[i].name + "<br> ";
        }

        $(this).parents('.input-type-file').find('label').html(filename);
    });
}


formInputFileAndCheckbx();




