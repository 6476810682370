/*global tarteaucitron */
tarteaucitron.lang = {
    "middleBarHead": "☝ 🍪",
    "adblock": "Привет! Этот сайт совершенно открытый и позволяет вам выбрать сервисы третьих лиц, которым вы хотите дать доступ.",
    "adblock_call": "Пожалуйста дезактивируйте АдБлокер чтобы начать настройку.",
    "reload": "Перезагрузите страницу",

    "alertBigScroll": "Продолжая прокрутки",
    "alertBigClick": "Если вы продолжаете использовать сайт",
    "alertBig": "вы позволяете сервисы третьих лиц",

    "alertBigPrivacy": "Этот сайт использует кукис и позволяет вам контролировать сервисы которые вы хотите активировать",
    "alertSmall": "Настройка сервисов",
    "personalize": "Персонализировать",
    "acceptAll": "Ок, все активировать",
    "close": "Закрыть",

    "privacyUrl": "Политика конфиденциальности",

    "all": "Преференция всем сервисам",

    "info": "Защитить вашу конфиденциальность",
    "disclaimer": "Активирование сервисов третьих лиц позволяет использование их кукис и технолоний отслеживания необходимых для их функционирования",
    "allow": "Позролить",
    "deny": "Не позволить",
    "noCookie": "Этот сервис не использует кукис.",
    "useCookie": "Этот сервис может быть инсталирован",
    "useCookieCurrent": "Этот сервис инсталирован",
    "useNoCookie": "Этот сервис не использует кукис.",
    "more": "Подробнее",
    "source": "Посетите официальный сайт",
    "credit": "Кукис манаджер tarteaucitron.js",

    "toggleInfoBox": "Show/hide informations about cookie storage",
    "title": "Панель управления cookies",
    "cookieDetail": "Cookie detail for",
    "ourSite": "on our site",
    "newWindow": "(new window)",
    "allowAll": "Разрешить использование cookies",
    "denyAll": "Запретить использование cookies",

    "fallback": "Деактивирован.",

    "ads": {
        "title": "Рекламная сеть",
        "details": "Мы позволяем вам аренду нашей рекламной сети."
    },
    "analytic": {
        "title": "Измерение аудиенции",
        "details": "Измерение аудиенции сайта для статистики помогают улучшить предлагаемый сервис."
    },
    "social": {
        "title": "Социальная сеть",
        "details": "Социальная сеть сайтов помогает улучшить предлагаемый сервис через обмен информации."
    },
    "video": {
        "title": "Видео",
        "details": "Обмен видео информации позволяет улучшить сервис и увеличит траффик сайта."
    },
    "comment": {
        "title": "Комментарии",
        "details": "Манаджер комментариев позволяет обмен информации и борьбу со спамом."
    },
    "support": {
        "title": "Помощь",
        "details": "Помощь позволяет вам контактировать напрямую сайт манаджер и улучшить предлагаемый сервис."
    },
    "api": {
        "title": "АПИ",
        "details": "АПИ используются для загрузки скриптов; геолокация, поисковый мотор и переводы..."
    },
    "other": {
        "title": "Другие",
        "details": "Службы для отображения веб-контента."
    },

    "mandatoryTitle": "Mandatory cookies",
    "mandatoryText": "This site uses cookies necessary for its proper functioning which cannot be deactivated.",
    "controle": "Управляйте своими данными"
};
