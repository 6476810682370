/* -------------------------------------------------------
 * ------------------ Attitude Manche ---------------------
 * ------------------------------------------------------- */

//spécificités listing Agenda

parseCardSitClickOverlay();

// OVERLAY MAP
function callbackMapOverlayMap(macarte) {
    macarte.setZoom(14);
    macarte.zoomControl.setPosition('topleft');
}

// OVERLAY SIT ITI - MAP
function callbackMapOverlaySIT(macarte) {
    macarte.zoomControl.setPosition('topleft');
}


// PAGE LISTING SIT
function callbackMapPageListingLEI(macarte) {
    macarte.setZoom(14);
    macarte.zoomControl.setPosition('topright');
}


// listing SIT classique - uniquement une map en overlay
var listingSitDefault = new thListingManager({
    selectors: {
        mapoverlay: '#map-listing',
        btn_loc: '.js-listing-btn-geoloc',
    },
    mapOverlayOptions: {
        useCluster: true,
    },
    afterUpdate: function (html) {

        // si composant favoris :
        th_favoris.parseItems();
        th_favoris.addEvents();
        parseCardSitClickOverlay();

        // gestion de l'affichage ou non du bouton pour voir la carte
        var nb_results = parseInt($(html).find(listingSitDefault.options.selectors.nb_results).text());
        if (nb_results > 100) {
            $('.js-listing-btn-showmap')
                .prop('aria-disabled', 'true')
                .prop('disabled', 'true')
                .addClass('deactivated-btn');
        } else {
            $('.js-listing-btn-showmap')
                .removeAttr('aria-disabled')
                .removeAttr('disabled')
                .removeClass('deactivated-btn');
            th_overlay.parse($('.js-listing-btn-showmap'));
        }
    }
});

/* A l'ouverture de l'overlay, on charge la carte */
// cette fonction doit $etre déclarée avant le th_overlay.init()
th_overlay.addCallbackOpen(function (overlayId) {
    if (overlayId == 'overlay-carte-listing-sit') {
        setTimeout(function () {
            if (listingSitDefault.loaded) {
                listingSitDefault.initMapOverlay();
                setTimeout(function () {
                    listingSitDefault.maps.mapOverlay.fitMap();
                }, 500);
            }
        }, 250);
    }
});

// RGAA : Au focus sur le bouton, si l'utilisateur appuye sur la touche "ESC" on cache la tooltip
var btnShowMapListing = $('.js-listing-btn-showmap');
btnShowMapListing.on("focus", function (e) {
    $(this).find(".tooltiptext").css("display", "block");
    $(this).keyup(function (e) {
        if (e.key === "Escape") {
            $(this).find(".tooltiptext").css("display", "none");
        }
    });
});


/* Initialisation du slider de la carte */

(function () {

    var sliders = document.querySelectorAll('.overlay-map .th-slider');
    for (i = 0; i != sliders.length; i++) {
        var slider = sliders[i];
        initSlider(slider);
    }

    function initSlider(slider) {
        var parent = slider.parentNode;

        var navButtons = parent.querySelectorAll('.nav-buttons button');
        var dotsWrapper = parent.querySelectorAll('.nav-dots');

        var s = new thSlider(slider, {
            draggable: true,
            scrollListener: true,
            scrollModeMaxWidth: '1024',
            oninit: function (slider) {

                if (navButtons.length === 2) {
                    thSliderTools.onInitNav(slider, navButtons);
                }

                if (dotsWrapper) {
                    thSliderTools.onInitDots(slider, dotsWrapper[0]);
                }

            },
            onchange: function (slider) {
                if (navButtons.length > 0) {
                    thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
                }
                if (dotsWrapper) {
                    thSliderTools.onChangeDots(slider, dotsWrapper[0]);
                }
            }
        });
    }
})();


/* -------------------------------------------------------
 * ------------------  Manche Emploi ---------------------
 * ------------------------------------------------------- */


// listing Entreprise / Cabinets - uniquement une map en overlay
var listingEntreprises = new thListingManager({
    selectors: {
        listing: '.js-listing-entreprises',
        mapoverlay: '#map-listing',
    },
    mapOverlayOptions: {
        useCluster: true,
    },
    afterUpdate: function (html) {

        // si composant favoris :
        th_favoris.parseItems();
        th_favoris.addEvents();
        parseCardEntrepriseClickOverlay();

        // // gestion de l'affichage ou non du bouton pour voir la carte
        var nb_results = parseInt($(html).find(listingEntreprises.options.selectors.nb_results).text());
        if (nb_results > 100) {
            $('.js-listing-btn-showmap').prop('disabled', 'true').addClass('deactivated-btn');
        } else {
            $('.js-listing-btn-showmap').removeAttr('disabled').removeClass('deactivated-btn');
        }
    }
});


// listing Actualité
var listingActualites = new thListingManager({
    selectors: {
        listing: '.js-listing-actualites',
    },
    afterUpdate: function (html) {
        // si composant favoris :
        th_favoris.parseItems();
        th_favoris.addEvents();
    }
});


// listing Offres d'emploi
var listingOffresEmploi = new thListingManager({

    selectors: {
        listing: '.js-listing-offres',
        mapoverlay: '#map-listing',
        cards: '.card-manche-emploi'
    },
    mapOverlayOptions: {
        useCluster: true,
    },
    afterUpdate: function (html) {

        th_favoris.parseItems();
        th_favoris.addEvents();
        parseCardOffresEmploiClickOverlay()

        // // gestion de l'affichage ou non du bouton pour voir la carte
        var nb_results = parseInt($(html).find(listingEntreprises.options.selectors.nb_results).text());
        if (nb_results > 100) {
            $('.js-listing-btn-showmap').prop('disabled', 'true').addClass('deactivated-btn');
        } else {
            $('.js-listing-btn-showmap').removeAttr('disabled').removeClass('deactivated-btn');
        }

    }

});

/* A l'ouverture de l'overlay, on charge la carte */
// cette fonction doit $etre déclarée avant le th_overlay.init()
th_overlay.addCallbackOpen(function (overlayId) {
    if (overlayId == 'overlay-carte-listing') {
        setTimeout(function () {
            if (listingEntreprises.loaded) {
                listingEntreprises.initMapOverlay();
                setTimeout(function () {
                    listingEntreprises.maps.mapOverlay.fitMap();
                }, 500);
            }
            if (listingOffresEmploi.loaded) {
                listingOffresEmploi.initMapOverlay();
                setTimeout(function () {
                    listingOffresEmploi.maps.mapOverlay.fitMap();
                }, 500);
            }
        }, 250);
    }
});